import React, { useCallback, useEffect, useRef, useState } from "react";
import ROLES from "App/Constants/Roles";
import RBAC from "App/HOCs/RBAC";
import moment from "moment";
import useRoleHook from "App/Hooks/useRoleHook";
import { get } from "App/Network/Axios";

import {
  Button,
  DateInput,
  Loader,
  ScrollableDynamicTable,
  SidePane,
} from "retro/index";
import { Alert, TOAST } from "retro/Alert/Alert";
import Filter from "retro/Filter";
import FilterDropDown from "App/Components/Filter/FilterDropDown";
import { DownloadCsv } from "retro/Utils";
import ReactJson from "react-json-view";

const HEADERS = [
  {
    weight: 2,
    title: "Title",
  },
  {
    weight: 2,
    title: "Description",
  },
  {
    weight: 2,
    title: "Owner",
  },
  {
    weight: 1.25,
    title: "Created At",
    sort: "createdAt",
  },
  {
    weight: 2,
    title: "Actions",
  },
];
const AllLogs = () => {
  let [loading, setLoading] = useState(true);
  const STARTDATE = useRef();
  const ENDDATE = useRef();
  const [data, setData] = useState({});
  const [logsModal, setLogsModal] = useState(false);
  const [dates, setDates] = useState({
    startDate: moment().add(-31, "d").valueOf(),
    endDate: moment().valueOf(),
  });
  const [filterModal, setFilterModal] = useState(false);
  const [filter, setFilter] = useState({
    client: [],
  });

  const [search, setSearch] = useState({
    page: 0,
    sort: "createdAt",
    order: "desc",
  });

  const [Data, SetData] = useState({
    total: 0,
    currentPage: 0,
    totalPages: 0,
    items: [],
  });
  const isSuper = useRoleHook(ROLES.SUPER);
  const loadPage = useCallback(
    (search) => {
      get(
        `/meta/cockpitlogs`,
        (err, res) => {
          if (res) {
            SetData({
              ...res,
              total: res.total ? res.total : Data.total,
              currentPage: res.currentPage,
              totalPages: res.totalPages ? res.totalPages : Data.totalPages,
            });
            setLoading(false);
          } else {
            Alert.handleError(err);
          }
        },
        {
          offset: search.page,
          order: search.order,
          sort: search.sort,
          q: search.q,
        }
      );
    },
    [Data, SetData]
  );
  useEffect(() => {
    loadPage(search);
  }, [search]);

  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <div className="flex horizontally">
        {logsModal && (
          <SidePane
            title="Logs"
            description="Details of all logs appears here"
            onClose={() => {
              setLogsModal(false);
            }}
          >
            <ReactJson src={data} />
          </SidePane>
        )}
      </div>

      {
        <RBAC role={ROLES.REPORTS}>
          <Button
            onClick={() => {
              setLoading(true);
              get(`/meta/cockpitlogs`, (err, res) => {
                console.log(err);
                if (res) {
                  let data = [];
                  let headers = [
                    "Id",
                    "Title",
                    "Description",
                    "Owner",
                    "Created On Date",
                  ];
                  data.push(headers);
                  res.items.forEach((item) => {
                    data.push([
                      item.client?.clientId || "-",
                      item?.title || "-",
                      item?.description || "-",
                      item.owner?.ownerName || "-",
                      moment(item.createdAt).format("DD MMM YYYY HH:mm"),
                    ]);
                  });
                  DownloadCsv(
                    `all_approvals_${moment().format("DD_MM_YYYY")}}.csv`,
                    data
                  );
                }
                setLoading(false);
              });
            }}
            style={{ zIndex: 11 }}
            loading={loading}
            className="btn absolute top-3 right-6 btn-primary"
          >
            <i className="fa fa-download mr1" />
            Download Data
          </Button>
        </RBAC>
      }

      {/* this for filter */}
      <div style={{ width: "100%" }}>
        <div
          style={{
            margin: "0 -4rem",
            padding: "1rem 4rem",
            borderBottom: "1px solid #dedede",
          }}
        >
          <Filter
            margin="0 -4rem"
            onFilter={() => {
              setFilterModal(true);
            }}
          />

          <FilterDropDown
            shown={filterModal}
            // filters={filter}
            setFilters={setFilter}
            choices={{
              client: [],
            }}
            types={["Client"]}
            onClose={() => {
              setFilterModal(false);
            }}
          />

          <div className="flex horizontally ">
            <DateInput
              label="From Date"
              className="flex-1 ml1 mr1 mt1"
              ref={STARTDATE}
              defaultValue={dates.start}
              onChange={() => {
                setDates({
                  ...dates,
                  start: STARTDATE.current.value,
                });
              }}
            />
            <DateInput
              label="To Date"
              ref={ENDDATE}
              defaultValue={dates.end}
              className="flex-1 mr1 ml1 mt1"
              onChange={() => {
                setDates({
                  ...dates,
                  end: ENDDATE.current.value,
                });
              }}
            />

            <div className="mt4">
              <Button
                onClick={() => {
                  if (filter.client.length === 0) {
                    TOAST.error("Enter ClientId");
                    return;
                  }
                  let clientid = filter.client[filter.client.length - 1].id;
                  get(
                    `/meta/cockpitlogs?start=${dates.startDate}&end=${dates.endDate}&clientIds=${clientid}`,
                    (err, res) => {
                      if (res) {
                        SetData({
                          ...res,
                          total: res.total ? res.total : Data.total,
                          currentPage: res.currentPage,
                          totalPages: res.totalPages
                            ? res.totalPages
                            : Data.totalPages,
                        });
                      } else {
                        Alert.handleError(err);
                      }
                    },
                    {
                      offset: search.page,
                      order: search.order,
                      sort: search.sort,
                      q: search.q,
                    }
                  );
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        </div>
      </div>

      <ScrollableDynamicTable
        setSearch={(search) => {
          setSearch({
            ...search,
            page: 0,
          });
        }}
        search={search}
        tableStyle={{
          borderSpacing: 0,
        }}
        setPage={(page) => {
          setSearch({
            ...search,
            page,
          });
        }}
        data={{
          current: Data.currentPage,
          total: Data.totalPages,
          items: Data.items.map((item) => {
            return {
              content: item,
              render: [
                {
                  children: (
                    <div>
                      <p className="text fw-bold truncate">{item.title || "-"}</p>
                    </div>
                  ),
                  weight: 2,
                },
                {
                  weight: 2,
                  children: <p className="truncate">{item.description || "-"}</p>,
                },

                {
                  weight: 2,
                  children: <p className="truncate">{item.owner.ownerName || "-"}</p>,
                },
                {
                  weight: 2,
                  children: (
                    <>{moment(item.createdAt).format("DD MMM YYYY HH:mm")}</>
                  ),
                },
                {
                  weight: 1,
                  children: (
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setLogsModal(true);
                        setData({ ...data, ...item });
                      }}
                    >
                      View
                    </p>
                  ),
                },
              ],
            };
          }),
        }}
        headers={isSuper ? HEADERS : HEADERS.slice(0, HEADERS.length - 1)}
      />
    </div>
  );
};

export default AllLogs;
