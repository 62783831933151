import React, { useEffect, useRef, useState } from 'react';
import {
  Attachment,
  Button,
  FileInput,
  Input,
  KeyValue,
  Loader,
  Modal,
  SelectInput,
  Seperator,
  SidePane,
  TOAST as Alert,
  TOAST,
  SearchInput,
  AttachmentPreview,
  DateRange,
  Loaders,
} from '../../../../../retro';
import moment from 'moment';
import { get, post } from '../../../../Network/Axios';
import SectionMessage from '@atlaskit/section-message';
import Moment from 'moment';

export default function ViewRequest({ id,getCount=()=>{}, onClose }) {
  const [data, setData] = useState({
    status: 'LOADING',
  });
  const [assignToOtherModal, setAssignToOtherModal] = useState(false)
  const [selectedAdmin, setSelectedAdmin] = useState();
  const [loader, setloader] = useState(false)

  const VENDOR_NAME = useRef();
  const INVOICE_NUMBER = useRef();
  const VISA_COUNTRY_NAME=useRef()
  const PASSPORT_NUMBER=useRef()
  const AMOUNT = useRef();
  const VISA_CONFIRMATION_NUMBER=useRef()
  const VISA_START_DATE=useRef()
  const VISA_END_DATE=useRef()
  const TYPE = useRef();
  const HANDLING= useRef()
  const REMARKS = useRef();
  const REQUESTID= useRef(null)
  const TRAINNAME = useRef();
  const TRAINNUMBER = useRef();
  const TRAINPNR = useRef();
  const TRAINSEAT = useRef();
  const [decline, setDecline] = useState(false);
  const FILE = useRef();
  const [file, setFile] = useState(undefined);
  const load = (id) => {
    get(`/meta/visa/${id}/details`, (e, r) => {
      if (r) {
        setData(r);

      } else {
        Alert.handleError(e);
      }
    });
  };
  useEffect(() => {
    load(id);
  }, [id]);

  const handleConfirmTicket = () => {
    let vendorName = VENDOR_NAME.current.value;
    let vendorInvoiceNumber = INVOICE_NUMBER.current.value;
    let visaType = TYPE.current.value;
    let amount = parseInt(AMOUNT.current.value, 10);
    const remarks = REMARKS?.current?.value || '';
    let visaConfirmationNumber= VISA_CONFIRMATION_NUMBER?.current?.value
    let country=VISA_COUNTRY_NAME?.current?.value
    let visaStartDate=VISA_START_DATE?.current?.value
    let visaEndDate= VISA_END_DATE?.current?.value
    let hendlingCharge=HANDLING?.current?.value
    // let invoiceNumber 
    let passportNumber= PASSPORT_NUMBER?.current?.value


    if (!vendorName) {
      TOAST.error('Enter a valid vendor name');
      return;
    }
    if (!vendorInvoiceNumber) {
      TOAST.error('Enter a valid invoice number');
      return;
    }

    if (visaType === 'Select Visa type') {
      TOAST.error('Enter a valid visa type');
      return;
    }

    if (!hendlingCharge) {
      TOAST.error('Enter a valid Hendling Charge');
      return;
    }
    if (!file) {
      TOAST.error('Select a valid file');
      return;
    }
    if (!amount) {
      TOAST.error('Enter a valid amount');
      return;
    }
    let requestData = {

      vendorName,
      vendorInvoiceNumber,
      visaConfirmationNumber,
       visaType,
       country,
       visaStartDate:Moment(visaStartDate).format('DD-MM-YYYY'),
       visaEndDate:Moment(visaEndDate).format('DD-MM-YYYY'),
       invoiceNumber:vendorInvoiceNumber,
       passportNumber,
       vendorPrice:amount,
       handlingPrice:hendlingCharge,
       totalPrice:Number(amount)+Number(hendlingCharge),
       file:file
    };

    setloader(true) 
    post(`/meta/visa/${id}/order`, requestData, (e, r) => {
      if (r) {
        setloader(false)
        getCount()
        TOAST.success('Visa Booking created successfully!');
        onClose();
      } else {
        setloader(false)

        TOAST.error(e);
      }
    });
  };

  if (data.status === 'LOADING') {
    return (
      <SidePane
        title="Visa Request"
        description="Details of this visa request queue appears below."
        onClose={onClose}
      >
        <Loader />
      </SidePane>
    );
  }
  return (
    <SidePane
      preview={
        <div className="pd4" style={{ width: 500 }}>
          <h3 className="fw-bold">Queue Details</h3>
          <div className="border pd4 mt4">
            <h3 className="fw-bold">Visa Request</h3>
            <p className="mb2">Details of this visa request appears below.</p>
            <div className="flex mt4 horizontally center-vertically">
              <KeyValue title="Status" value={data.status} />
              <KeyValue title="Request Id" value={data.requestId} />
            </div>
            <div className="flex mt4 horizontally center-vertically">
              <KeyValue title="Client Code" value={data.client?.clientCode} />
              <KeyValue title="Client Name" value={data.client?.clientName} />
            </div>
          <Seperator margin={2}/>
          <div className="flex mt4 horizontally center-vertically">
					<KeyValue title="Country" value={data?.visa?.country}/>
					<KeyValue title="Current Passport Number" value={data?.visa?.passportNumber}/>
				</div>
        <div className="flex mt4 horizontally center-vertically">
					<KeyValue title="Travel Date" value={data?.visa?.startDate}/>
					<KeyValue title="Current Work Location" value={data?.visa?.address}/>
				</div>
        <div className="flex mt4 horizontally center-vertically">
					<KeyValue title="Purpose of visit" value={data?.visa?.purposeOfVisit}/>
					<KeyValue title="Nationality" value={data?.visa?.nationality}/>
				</div>
        <div className="mt4">
					<KeyValue title="Client Name" value={data?.gstDetails?.name || '-'}/>
					<KeyValue title="Client Gstin" value={data?.gstDetails?.gstin || '-'}/>
				</div>
            <Seperator margin={2} />
            <div>
              <p className="fw-bold">Travellers:</p>
              {/* {
						data?.traveller.map((item, index) => { */}

              <div
                key={data?.traveller.email}
                className="mt2 flex horizontally"
              >
                <p className="fw-bold">{/* {index + 1}) */}</p>
                <div className="ml1 flex-2">
                  <p className='fw-bold'>{data?.traveller?.name}</p>
                  <p className="text-small"><span className='fw-bold'>Email:</span> {data?.traveller?.email}</p>
                  <p className="text-small"><span className='fw-bold'>Mobile:</span> {data?.traveller?.mobile}</p>
                </div>
                <div className="flex-1">
                  {data?.traveller?.gender}(
                  {Moment().diff(Moment(data?.traveller?.dob), 'years', false)}{' '}
                  yrs old)
                </div>
              </div>
                <div className='flex horizontally ml1'>
                  <div className='flex-1'>
                    <p className='text-small'>
                      <span className='fw-bold'>Office:</span>  {data?.traveller?.office}
                    </p>
                    <p className='text-small'>
                    <span className='fw-bold'>Department:</span> {data?.traveller?.department}
                    </p>
                    </div>
                    <div className='flex-1'>
                    <p className='text-small '>
                    <span className='fw-bold'>Team:</span> {data?.traveller?.team}
                    </p>
                    <p className='text-small'>
                    <span className='fw-bold'>Policy:</span> {data?.traveller?.policy}
                    </p>
                  </div>
							</div>
              {/* )
						})
					} */}
            </div>
            <Seperator margin={2}/>
            <div>
            <p className="fw-bold">Attachments:</p>

           <div className="mt2 flex horizontally">
                  {data?.visa?.attachments.map((doc)=>{
                  return(
                  <Attachment url={doc} />
                  )
                  })}
            </div>
            


            </div>
          </div>
        </div>
      }
      title="Visa Request"
      description="Details of this visa request queue appears below."
      onClose={onClose}
    >
      {data.status === 'COMPLETED' ||
      data.status === 'REFUNDING' ||
      data.status === 'CANCELLED' ||
      data.status === 'REFUNDED' ? (

        <div className="mt4 border pd4">
          <div className="flex mt4 horizontally center-vertically">
					<KeyValue title="Vonder Name" value={data?.ticket?.vendorName}/>
					<KeyValue title="Vendor Invoice" value={data?.ticket?.vendorInvoiceNumber}/>
				</div>
          <div className="flex mt4 horizontally center-vertically">
          <KeyValue title="Vendor Conformation" value={data?.ticket?.visaConfirmationNumber}/>
					<KeyValue title="Visa Type" value={data?.ticket?.visaType}/>
				</div>
          
          <div className="flex mt4 horizontally center-vertically">
					<KeyValue title="Visa  Start" value={data?.ticket?.visaStartDate}/>
          <KeyValue title="Visa End Date" value={data?.ticket?.visaEndDate}/>

				</div>
          
         
        <div className="flex mt4 horizontally center-vertically">
					<KeyValue title="Vendor Amount" value={data?.ticket?.vendorPrice}/>
					<KeyValue title="Hendling Charge" value={data?.ticket?.handlingPrice}/>
				</div>

        <div className="flex mt4 horizontally center-vertically">
					<KeyValue title="Total Amount" value={data?.ticket?.totalPrice}/>
				</div>


        <Seperator margin={2}/>
            <div>
            <p className="fw-bold">Attachments:</p>

           <div className="mt2 flex horizontally">
                  {data?.ticket?.files.map((doc)=>{
                  return(
                  <Attachment url={doc} />
                  )
                  })}
            </div>
            


            </div>
          

          
        </div>
      ) : undefined}
      {data.status === 'QUEUED' && (
        <div className="mt4">
          <div className="mb2">
            <SectionMessage title={data.admin.adminName}>
              <p>This ticket is currently handled by {data.admin.adminEmail}</p>
              <div className="pt2">
                <p
                  onClick={() => {
                    post(`/meta/visa/${id}/self`, {}, (e, r) => {
                      if (r) {
                        load(id);
                        TOAST.success('Ticket assigned to you.');
                      } else {
                        Alert.handleError(e);
                      }
                    });
                  }}
                  className="btn-link btn"
                >
                  Assign To Yourself  
                </p>
                <span> / </span>

                <p
                  onClick={() => {
                    setAssignToOtherModal(true)
                  }}
                  className="btn-link btn"
                >
                  Assign To Other
                </p>
              </div>
            </SectionMessage>
          </div>

          <div className="flex horizontally center-vertically">
          <Input

              defaultValue={data?.visa?.country}
              ref={VISA_COUNTRY_NAME}
              type="text"
              label="Visa Country"
              placeholder="Enter Visa Country name"
              className="mr1 flex-1"
              disabled={true}
            />
          </div>

          <div className="flex horizontally center-vertically mt2">
          <Input
              ref={PASSPORT_NUMBER}
              defaultValue={data?.visa?.passportNumber}

              type="text"
              label="Traveler Passport Number"
              placeholder="Enter Traveler Passport Number"
              className="mr1 flex-1"
            />
          </div>

          <div className="flex horizontally center-vertically mt2">
            <Input
              ref={VENDOR_NAME}
              type="text"
              label="Vendor Name"
              placeholder="Enter vendor name"
              className="mr1 flex-1"
            />
            </div>
           <div className="flex horizontally center-vertically mt2">

            <Input
              ref={INVOICE_NUMBER}
              type="text"
              label="Vendor Invoice Number"
              placeholder="Enter Vendor invoice number"
              className="mr1 flex-1"
            />
          </div>


          <div className="flex horizontally center-vertically mt2">
          <Input
              ref={AMOUNT}
              type="number"
              label="Enter Vendor Amount"
              placeholder="Enter Vendor Amount"
              className="mr1 flex-1"
            />
          </div>

          <div className="flex horizontally center-vertically mt2">
          <Input
              ref={VISA_CONFIRMATION_NUMBER}
              type="text"
              label="Enter Visa Confirmation Number"
              placeholder="Enter Visa Confirmation Number"
              className="mr1 flex-1"
            />
          </div>
          <div className="flex horizontally center-vertically mt2">
          <DateRange
          defaultValue={moment(data?.visa?.startDate , 'DD-MM-YYYY').format('MM-DD-YYYY')}   // dd-mm-yyyy  mm-dd-yyyy
              ref={VISA_START_DATE}
              label="Visa Start Date"
              placeholder="Enter Visa Start Date"
              className="mr1 flex-1"
            />
            
             <DateRange
              ref={VISA_END_DATE}
              // type="date"
              label="Visa End Date"
              placeholder="Enter Visa End Date"
              className="mr1 flex-1"
            />
          </div>

          <div className="flex horizontally center-vertically mt2">
            <SelectInput
              options={[
                { label: 'Select Visa type' },
                { label: 'Employment Visa', value: 'Employment Visa' },
                { label: 'Tourist Visa', value: 'Tourist Visa' },
                { label: 'Business Visa', value: 'Business Visa' },
                { label: 'Project Visa', value: 'Project Visa' },
                { label: 'Conference Visa', value: 'Conference Visa' },
              ]}
              ref={TYPE}
              label="Visa Type"
              hidePlaceholder={true}
              className="mr1 flex-1"
            />
          </div>

          <div className="flex horizontally center-vertically mt2">
            <Input
              ref={HANDLING}
              type="text"
              label="Handling Charges"
              placeholder="Enter Handling Charges"
              className="ml1 flex-1"
            />
          </div>
          <div className="flex horizontally center-vertically mt2">
            <Input
              ref={REMARKS}
              type="text"
              label="Remarks(optional)"
              placeholder="Enter remarks"
              className="ml1 flex-1"
            />
          </div>




          {/* <div className="flex horizontally center-vertically mt2">
						<div className="flex mb2 mt2">
							<FileInput updateFiles={(url) => {
								setFile(url)
							}} label="" ref={FILE}/>
							<Attachment url={file}/>
						</div>
					</div> */}

          <div className="flex horizontally center-vertically mt2">
            <div className="flex mb2 mt2">
              <FileInput
                updateFiles={(url) => {
                  setFile(url);
                }}
                label=""
                ref={FILE}
              />
              
              <Attachment url={file} />

           </div>
          </div>

          {loader?(
            <>
            <Loader/>
            </>
          ):<>
 <div className="flex horizontally center-vertically">
 <Button
   onClick={handleConfirmTicket}
   className="mt4 btn-black btn-primary"
   margin=""
 >
   Confirm Visa
 </Button>
 <Button
   onClick={() => {
     setDecline(true);
   }}
   className="mt4 btn-danger btn-primary"
   margin="ml2"
 >
   Decline 
 </Button>
</div>
        </>  
        }
         
        </div>
      )}
      {decline && (
        <Modal
          onClose={() => {
            setDecline(false);
          }}
          title="Decline Remarks"
          description="Enter your decline remarks"
        >
          <Input
            label="Remarks"
            placeholder="Enter your remarks"
            ref={REMARKS}
          />
          <Button
            onClick={() => {
              let remarks = REMARKS.current.value;
              if (!remarks) {
                TOAST.error('Enter a valid remarks');
                return;
              }
              post(`meta/visa/${id}/decline`, { remarks }, (e, r) => {
                if (r) {
                  TOAST.success('Declined successfully!');
                  onClose();
                } else {
                  Alert.handleError(e);
                }
              });
            }}
          >
            Cancel Booking
          </Button>
        </Modal>
      )}


{assignToOtherModal && (
		<Modal
			onClose={() => {
				setAssignToOtherModal(false);
				setSelectedAdmin(undefined);
			}}
			title="Assign To Other"
			description="Select an admin to assign"
			style={{
				height: 500
			}}
		>
			<SearchInput
				renderResult={item => {
					return (
						<p style={{ fontSize:14 }}>
							{item.name} ({item.email})
						</p>
					)
				}}
				resultKey="items"
				api="/admins"
				morph={false}
				label="Select Admin"
				onSelect={admin => {
					setSelectedAdmin(admin);
				}}
				placeholder="Select an admin"
			/>
			<Button
				onClick={() => {
					if (!selectedAdmin) {
						return TOAST.error('Select an admin to assign');
					}

					post(`/meta/visa/${id}/${selectedAdmin.id}/assign`, {}, (e, r) => {
						if (r) {
							load(id);
							TOAST.success(`Ticket assigned to ${selectedAdmin.name}`);

							setSelectedAdmin(undefined);
							setAssignToOtherModal(false);
						} else {
							Alert.handleError(e);
						}
					});
				}}
				className='btn-black btn-primary'
			>
				Assign
			</Button>
		</Modal>
	  )}
    </SidePane>
  );
}
