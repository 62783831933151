import React, {useEffect, useRef, useState} from 'react'
import {Button, Input, Modal, Pages, TOAST, SelectInput, SearchInput} from "../../../../../retro";
import {get, post} from "../../../../Network/Axios";
import {useParams} from "react-router-dom";

export default function VirtualAccountSettings() {
	const [modal,setModal] = useState(false)
    const [defaultAccount,setDefaultAccount] = useState();
    const [virtualAccounts,setVirtualAccounts] = useState([]);
	const VIRTUALACCOUNT = useRef();
	const {id} = useParams();

	// function to get the default va data
	const load = id => {
        get(`accounts/${id}`,(e,r)=>{
			if(r){
                let transformedAccounts = undefined
                if(r?.items){
                    transformedAccounts = r.items.map(account => ({
                        label: `${account.title}  (${account.type}) (Rs. ${account.balance})`,
                        value: account.id
                      }));
                }
                setVirtualAccounts(transformedAccounts);
                let defaultAccount = undefined
                r?.items?.forEach((item)=>{
                    if(item.defaultAccount){
                        defaultAccount = item;
                    }
                })
                setDefaultAccount(defaultAccount);
			}
		});
	}

	// useEffect to fetch data whenever there is a change in id and first time page loads

	useEffect(()=>{
		load(id)
	},[id])
	return (
		<div>
			<Pages.Title title="Default Virtual Account" description="Select default virtual account for this client."/>
			<div className="border mt2 mb2 rounded-sm pd2">
				{
					// if default account exists show data else show No va found
					defaultAccount?<>
						<p className="fw-bold">
							{defaultAccount?.title || '-'} {`(${defaultAccount?.type || '-'})`}
						</p>
						<p className="text-small">
							Rs. {defaultAccount?.balance || 0}
						</p>
					</>:<p>
						No virtual accounts found.
					</p>
				}
			</div>
			<Button onClick={()=>{
				setModal(true)
			}} className="btn btn-black">
				Change
			</Button>
			{
				// modal to update default VA which pops up when user clicks on update button
				modal&&
				<Modal title="Default Virtual Account" description="Select virtual account to make it default." onClose={()=>{
					setModal(false);
				}}>
                    <SelectInput
						options={virtualAccounts}
						ref={VIRTUALACCOUNT}
						type='number'
						label='Virtual Accounts'
						placeholder = "Select virtual account"
						className='mr1 flex-1'
					/>
					<Button debounce={true} className="btn-primary btn-max mt4" onClick={()=>{
                        let virtualAccountId = VIRTUALACCOUNT?.current?.value
                        if(!virtualAccountId || virtualAccountId==="NONE"){
                            TOAST.error("Select virtual account");
                            return;
                        }
						post(`accounts//account/${id}/${virtualAccountId}/default`,{},(e,r)=>{
							if(r){
								load(id)
								setModal(false)
								TOAST.success("Default virtual account updated!")
							}else{
								TOAST.handleError(e)
							}
						})
					}}>
                        Set as default
					</Button>
				</Modal>
			}
		</div>
	)
}
