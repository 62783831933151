import React, { useState } from 'react';
import { Button, DateRange, Navigation, TOAST } from 'retro';
import Moment from 'moment';
import { reportGet as get } from 'App/Network/Axios';
import { DownloadCsv } from 'retro/Utils';
import Graph from './graph-stats-circle.svg';
import RBAC from 'App/HOCs/RBAC';
import ROLES from 'App/Constants/Roles';

export default function index() {
  const [Dates, setDates] = useState();
  const [loading, setLoading] = useState(false);

  const findFareFamily = (item)=>{
    if(!item){
      return '-'
    }
    return `${item?.ticket?.fareType || '-'},${item?.ticket?.returnFareType || '-'}`
  }

  let airlines = (item) => {
    let string = undefined;
    try {
      item?.ticket?.segments.forEach((segment) => {
        if (!string) {
          string = `${segment.airline}`;
        } else {
          string = `${string} , ${segment.airline}`;
        }
      });
    }catch (e){
      return "-";
    }
    return string;
  };
  let flightCode = (item) => {
    let string = undefined;
    try{
      item.ticket.segments.forEach((segment) => {
        if (!string) {
          string = `${segment.flightCode} ${segment.flightNumber}`;
        } else {
          string = `${string} , ${segment.flightCode}  ${segment.flightNumber}`;
        }
      });
    }catch (e){
      return "-"
    }
    return string;
  };

  let airlineSegments = (item)=>{
    try{
      let numberOfTravellers = item.ticket.travellers.length
      // if category is not flight return '-'
      if(!item?.category?.toUpperCase()?.includes("FLIGHT")){
        return '-'
      }
      let oneWay = item?.category?.toUpperCase()==="DOMESTICFLIGHT"?1:item?.category?.toUpperCase()==="DOMESTICROUNDFLIGHT"?2:0
      return numberOfTravellers*(oneWay);
    }catch{
      return "-"
    }
  }

  const downloadData = () => {
    setLoading(true);
    get(
      `/meta/all/all`,
      (e, r) => {
        if (e) {
          setLoading(false);
          TOAST.error('Unable to download');
          console.log(e)
          return;
        }
        let data = [];
        let headers = [
          'Id',
          'Provider',
          'Category',
          'Booking Id',
          'Status',
          'Provider Id',
          'Payment Type',
          'Amount',
          'Ledger Id',
          'Ledger Amount',
          'Ledger Balance',
          'Voucher',
          'Gstin',
          'Client Code',
          'Client Name',
          'Traveller Name',
          'Travellers Count',
          'Domestic/International',
          'Created On Date',
          'Created On Time',
          'Updated On Date',
          'Updated On Time',
          'Refund Amount',
          'Service Charge Base Fare',
          'Service Charge Cgst',
          'Service Charge Sgst',
          'Service Charge Igst',
          'Service Charge Slab',
          'Service Charge',
          'Service Charge Invoice',
          'Description',
          'Discount',
          'Nights/Segments',
          'Platform',
          "Airline Segment",
          "Fare Family",
          'Airline Code',
          'Airline Name'
        ];
        data.push(headers);
        r?.bookings?.map((item) => {
          if (item.ticket) {
            data?.push([
              item.id,
              item.provider,
              item.category,
              item.transId,
              item.status,
              item.ticket?.ticket || item.ticket?.pnr || '-',
              item.paymentType,
              item.amount,
              item.ledger?.ledgerId || '-',
              item.ledger?.amount || '-',
              item.ledger?.balance || '-',
              item.invoiceConfig?.file || '-',
              item.invoiceConfig?.userGst?.gstin || '-',
              item.client.code,
              item.client.name,
              traveller(item),
              item?.ticket?.travellers?.length || '-',
              item?.invoiceConfig?.isInternational
                ? 'International'
                : 'Domestic' || '-',
              Moment(item.createdAt).format('DD MMM YYYY'),
              Moment(item.createdAt).format('HH:mm'),
              Moment(item.updatedAt).format('DD MMM YYYY'),
              Moment(item.updatedAt).format('HH:mm'),
              item.refundAmount || '-',
              item.invoiceConfig?.serviceFare?.base || '-',
              item.invoiceConfig?.serviceFare?.cGst || '-',
              item.invoiceConfig?.serviceFare?.sGst || '-',
              item.invoiceConfig?.serviceFare?.iGst || '-',
              item.invoiceConfig?.serviceFare?.slab || '-',
              item.invoiceConfig?.serviceCharges || '-',
              item.invoiceConfig?.serviceInvoice || '-',
              item.ticket?.bus?.label ||
              item.ticket?.name || item.ticket?.segments?.[0].airline || '-',
              item.invoiceConfig?.discount || '-',
              item?.nightsOrSegment || '-',
              item?.platform !==''? item?.platform :'-',
              airlineSegments(item) || '-',
              findFareFamily(item),
              flightCode(item),
              airlines(item),
            ]);
          }
        });
        DownloadCsv(
          `all_bos_${Moment(Dates.startDate).format('DD_MM_YYYY')}_${Moment(
            Dates.endDate
          ).format('DD_MM_YYYY')}.csv`,
          data
        );
        setLoading(false);
      },
      {
        start: Dates.startDate,
        end: Dates.endDate,
      }
    );
  };

  let traveller = (item) => {
    let string = undefined;
    item?.ticket?.travellers?.forEach((traveller) => {
      if (!string) {
        string = `${traveller.name}`;
      } else {
        string = `${string} , ${traveller.name}`;
      }
    });
    return string;
  };
  return (
    <div style={{ width: '100%' }}>
      <Navigation
        backUrl={'back'}
        title="All Bookings"
        chips={['Meta', 'All Bookings']}
        description="Bus bookings appears below."
      />
      <div
        style={{
          margin: '0 -4rem',
          padding: '2rem 4rem',
          borderBottom: '1px solid #dedede',
        }}
      >
        <div className="flex horizontally center-vertically">
          <div className="flex-1">
            <DateRange
              defaultValue={{
                startDate: Moment().add(-31, 'd').valueOf(),
                endDate: Moment().valueOf(),
              }}
              onChange={(dates) => {
                setDates({
                  ...dates,
                  page: 0,
                });
              }}
              className=""
              label="Export from and to"
              type="range"
            />
          </div>
        </div>
      </div>
      <div>
        <RBAC role={ROLES.REPORTS}>
          <Button
            loading={loading}
            onClick={downloadData}
            className="btn btn-black"
          >
            Download Data
          </Button>
        </RBAC>

        <div className="border pd6 mt4 flex vertically center">
          <img
            alt="fetch"
            src={Graph}
            style={{ width: '5rem', height: '5rem' }}
          />
          <h3 className="fw-bold mt4">No Data Found</h3>
          <p>No data found in this configuration</p>
        </div>
      </div>
    </div>
  );
}
