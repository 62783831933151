import React, {useEffect, useRef, useState} from 'react'
import {useParams} from "react-router-dom";
import {get, post} from "../../../../Network/Axios";
import {Button, Input, KeyValue, Modal, TOAST,Switch} from "../../../../../retro";

export default function User() {
    
    let saas = window.location.href.includes('cockpit.dice.tech') || window.location.href.includes('localhosts');
    const {id} = useParams();
    const ID = useRef();
    const EP = useRef();
    const CERT = useRef();
    const PW = useRef(),ENTITY=useRef();
    const [config,setConfig] = useState({})
    const [entityConfig,setEntityConfig] =useState({});
    const [modal,setModal] = useState(false);
    const [entityEnable,setEntityEnable] = useState(false);
    const load = id => {
        get(`/clients/saml/${id}`,(e,r)=>{
            if(r){
                setConfig(r.ssoConfig)
                setEntityConfig(r.entitySsoConfig);
            }
        });
    }
    const toggle = id => {
        if(!config.enabled){
            setModal(true)
        }else{
            post(`/clients/saml/${id}/toggle`,{},(e,r)=>{
                if(r){
                    load(id)
                }
            });
        }
    }
    useEffect(()=>{
        load(id)
    },[id])


    let mapItem = (item,index) => {
        let mItem = entityConfig[item]
        return(
            <div key={index} className='mt4 border-bottom flex horizontally'>
                <div className='flex-1'> 
                     <p className="text text-light">
                        Entity {item}
                     </p>
                    <p className="">
                        {config.endPoint}
                    </p>
                    <KeyValue title="Id" value={mItem.id} margin="mt1"/>
                    <KeyValue title="Cert" value={mItem.cert}/>
                </div>
                <div>
                <Switch
                    onChange={() => {
                        post(`/clients/saml/${id}/toggle`,{entity:item},(e,r)=>{
                            if(r){
                                load(id)
                            }
                        });
                    }}
                    state={mItem.enabled}
                />
                </div>
               
            </div>
        )
    }

    return(
        <>
            {
                modal && <Modal title="Sso Config" description="Sso configuration" onClose={()=>{setModal(false)}}>
                    {
                    saas &&
                    <>
                       <div className='flex'>
                            <div className='flex-1'>
                                Enable Entity
                            </div>
                            <Switch
                                onChange={() => {
                                    setEntityEnable(!entityEnable)
                                }}
                                state={entityEnable}
                            />
                       </div>
                       {
                        entityEnable &&
                        <Input  allowSpecialCharacters={true} type="text" ref={ENTITY} placeholder="Enter Entity(optional)" label="Enter Entity(optional)"/>
                       }

                    </>
                    }
                    <Input allowSpecialCharacters={true} type="text" ref={ID} placeholder="Enter Id" label="Name Id"/>
                    <Input allowSpecialCharacters={true} type="text" ref={EP} placeholder="Enter Endpoint" label="Endpoint"/>
                    <Input allowSpecialCharacters={true} type="text" ref={CERT} placeholder="Enter Cert" label="Certificate"/>
                    <Input allowSpecialCharacters={true} type="text" ref={PW} placeholder="Enter Password" label="Password"/>
                    <Button margin="mt4" onClick={()=>{
                        let mId = ID.current.value;
                        let ep = EP.current.value;
                        let crt = CERT.current.value;
                        let pw = PW.current.value;
                        let data = {
                            id:mId,ep,crt,pw
                        }
                        if(saas&& entityEnable) {
                            data.entity = ENTITY.current.value;
                        }
                        post(`/clients/saml/${id}`,data,(e,r)=>{
                            if(r){
                                TOAST.success("SAML configuration successful!!")
                                setModal(false)
                                load(id)
                            }
                        })
                    }} className="btn btn-black">
                        + Add Sso
                    </Button>
                </Modal>
            }
            <div className="border rounded-md pd4 mt4">
                <h2 className="fw-bold">
                    Authentication Settings
                </h2>
                <p>
                    Enable authentication settings appears below.
                </p>
                {
                    config.enabled&&<div className="mt4">
                        <p className="text text-light">
                            Endpoint:
                        </p>
                        <p className="">
                            {config.endPoint}
                        </p>
                        <KeyValue title="Id" value={config.id} margin="mt1"/>
                        <KeyValue title="Cert" value={config.cert}/>
                    </div>
                }
                {
                    saas &&
                    Object.keys(entityConfig).map(mapItem)
                }
                <Button onClick={toggle.bind(this,id)} className="btn btn-black" margin="mt4" style={{width:300}}>
                    {
                        config.enabled ? "- Disable Authentication":"+ Enable Authentication"
                    }
                </Button>
            </div>
        </>
    )
}
