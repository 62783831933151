import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  ChipsSelect,
  Input,
  Loader,
  ScrollableDynamicTable,
  SidePane,
  TOAST,
  TOAST as Alert,
  SearchBox,
  Seperator,
  Tags,
  Switch
} from "retro";
import { get, post } from "App/Network/Axios";
import EditIcon from "@atlaskit/icon/glyph/edit";
import DeleteIcon from "@atlaskit/icon/glyph/trash";
import ROLES, { YESBNK_ROLES,CT_ROLES } from 'App/Constants/Roles';
import RESTRICT from "App/Constants/RestrictViews";
import SelectClient from '../AddAdmin/SelectClient';
import useConfigHook from 'App/Hooks/useConfigHook';
import moment from "moment";
import { DownloadCsv } from "retro/Utils";

export default function index() {
  const CONFIG = useConfigHook();
  const NAME = useRef();
  const EMAIL = useRef();
  const MOBILE = useRef();
  const EMPLOYEEID = useRef();
  const [roles, setRoles] = useState([]);
  const [isSuper,setIsSuper] = useState(false);
  const [restrict, setRestrict] = useState([]);
  let [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(undefined);
  const [addClient,setAddClient] = useState(false);   // state to open modal to add client 
  const [adminDataLoading, setAdminDataLoading]=useState(false);
  const [allClients,setAllClients] = useState([]);
  const [selectedClient,setSelectedClient] = useState([]);
  const [search, setSearch] = useState({
    page: 0,
    sort: "id",
    order: "desc",
    q: undefined,
  });
  const [Data, SetData] = useState({
    total: 0,
    currentPage: 0,
    totalPages: 0,
    items: [],
  });
  // set the user roles to be displayed
  const userRoles = CONFIG.origin.code === 'YESUAT' || CONFIG.origin.code==='YES-PROD' ? YESBNK_ROLES : ROLES;
  const [CtRolesModal, setCtRolesModal] = useState({
    FINANCE:false,
    SALES:false,
    OPERATIONS:false,
  })
  const ctRoles = (CONFIG.origin.code === 'CLRTRP' || CONFIG.origin.code === 'CLT-STG')? CT_ROLES : undefined;  // object having values as array of roles

  const loadPage = useCallback(
    (search) => {
      get(
        `/admins`,
        (e, r) => {
          if (r) {
            SetData({
              ...r,
            });
            setLoading(false);
          } else {
            Alert.handleError(e);
          }
        },
        {
          offset: search.page,
          sort: search.sort,
          order: search.order,
          q: search.q,
        }
      );
    },
    [SetData]
  );


  useEffect(() => {
    loadPage(search);
  }, [search]);


  useEffect(()=>{
    if(modal) {
      get(`/clients`,(e,r)=>{
        if(r) {
            setAllClients(r.items)
            let items = [];  // fetch the selected client of particular admin
            r.items.forEach(i=>{
              if(_.includes(modal.clientIds,i.id)) {
                items.push(i.email)
              }
            })

            setSelectedClient(items);
        }
    })
    }
  },[modal])

  
  let fetchArrayofName = (all,selected) => {  /* To get id of selected client */
    let items = [];
    all.forEach(item=>{
        if(_.includes(selected,item.email)) {
            items.push(item.id)
        }
    })

    return items;
}

const onMobileKeyDown = (e)=>{
  if(e.key === 'ArrowRight' || e.key === 'ArrowLeft' || e.key === 'Backspace') {
    return;
  }
  if (MOBILE.current.value.length === 10 || !/^[0-9]/.test(e.key)) {
    e.preventDefault();
  }
}

  // toggle function to select or deselect super
  const toggleSuper = ()=>{
    if(isSuper){
      setRoles([]);
      setIsSuper(false);
      setCtRolesModal({
        FINANCE:false,
        SALES:false,
        OPERATIONS:false,
      })
    }
    else{
      setRoles([...Object.keys(userRoles),"SUPER"]);
      setIsSuper(true);
    }
  }

  // set super true if all the roles are selected
  useEffect(()=>{
    if(roles.length === Object.keys(userRoles).length){
      setIsSuper(true);
      setRoles([...Object.keys(userRoles),"SUPER"]);
    }
  },[roles])

  if (loading) {
    return <Loader />;
  }
  const click = (item) => {
    setModal(item);
    if(item.roles.includes("SUPER")){
      setIsSuper(true);
    }else{    //https://dice-antrepriz.atlassian.net/browse/CTOOO-233
      setIsSuper(false);
    }
    setRoles(item.roles);
    setRestrict(item?.restrictViews || []);
  };
  const deleteItem = (item) => {
    post(`/admins/${item.id}/delete`, {}, (e, r) => {
      if (r) {
        TOAST.success("User deactivated successfully");
        loadPage(search);
      } else {
        TOAST.handleError(e);
      }
    });
  };
  function validateEmail(email) {
    var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
    }
  function validateEmpCode(code) {
    var pattern = /^[a-zA-Z0-9']+$/;
    return pattern.test(code);
}
const updateRoles = () => {
  if(roles.includes('CLIENTS_WRITE') && !roles.includes('CLIENTS')) {
    const updatedRoles = [...roles, 'CLIENTS'];
    setRoles(updatedRoles);
    return roles;
  }
  return roles;
};
const handleSelected = () =>{
  if(window.origin.includes("yesleap") || window.origin.includes('yespayleaf')){
    return updateRoles()
  }else{
    return roles}}
  const downloadAdminData=()=>{
    setAdminDataLoading(true);
    get('/admins/all', (e, r)=>{
      setAdminDataLoading(false);
      if(r){
      let data=[];
      let headers=[
        'Name',
        'Email',
        'Created At',
        'Roles',
        'Last Login'
      ]
      data.push(headers);
      r.items.map((item)=>[
        data.push([
          item.name ||'-',
          item.email ||'-',
          moment(item.createdAt).format('DD-MMM-YYYY') || '-',
          item.roles.join(', ') ||'-',
          moment(item.lastLogin).format('DD-MMM-YYYY HH:mm') || '-'
        ])
      ])
      DownloadCsv(`admins_on_${moment().format('DD_MM_YYYY')}.csv`, data);

      }
    })
  }

  const updateUser = () => {
    let name = NAME.current.value;
    let email = EMAIL.current.value;
    let mobile = MOBILE.current.value;
    let code = EMPLOYEEID.current.value;
    if (!name || name.length < 2 || name[0]===" ") {
      TOAST.error("Kindly enter a valid name");
      NAME.current.style.border = '2px solid red';
      return;
    }
    if (!email) {
      TOAST.error("Kindly enter a valid email");
      EMAIL.current.style.border = '2px solid red';
      return;
    }
    if (!validateEmail(email)) {
      TOAST.error("Kindly enter a valid email");
      EMAIL.current.style.border = '2px solid red';
      return;
    }
    if (!mobile || mobile.length !== 10 || parseInt(mobile) < 5000000000) {
      TOAST.error("Kindly enter a valid mobile");
      MOBILE.current.style.border = '2px solid red';
      return;
    }
    if (!code || code.length < 2 || !validateEmpCode(code)) {
      TOAST.error("Kindly enter a valid code");
      EMPLOYEEID.current.style.border = '2px solid red';
      return;
    }
    if (roles.length < 1) {
      TOAST.error("Kindly select atleast one role.");
      return;
    }
    let data = {
      name,
      email,
      mobile,
      code,
      roles,
      clientIds:fetchArrayofName(allClients,selectedClient),
      restrictViews:restrict,
    };
    post(`/admins/${modal.id}/update`, data, (e, r) => {
      if (r) {
        NAME.current.value = "";
        EMPLOYEEID.current.value = "";
        EMAIL.current.value = "";
        MOBILE.current.value = "";
        TOAST.success("User updated successfully");
        setModal(undefined);
        loadPage(search);
        TOAST.success("Saved and sent for approval.");
      } else {
        TOAST.handleError(e);
      }
    });
  };
  return (
    <div>
      {modal !== undefined && (
        <SidePane
          onClose={() => {
            setModal(undefined);
          }}
          zIndex={20}
          description='Enter details below to edit admin'
          title='Edit Admin'
        >
          <Input 
            onKeyDown={(e) => {
              if (!/^[a-zA-Z ]*$/.test(e.key)) {
                e.preventDefault();
              }
            }} 
            ref={NAME} 
            defaultValue={modal.name} 
            label='Name *'
          />
          <Input ref={EMAIL} defaultValue={modal.email} label='Email Address *' />
          <Input
            onKeyDown={(e) => onMobileKeyDown(e)}
            ref={MOBILE}
            defaultValue={modal.mobile}
            type='text'
            label='Mobile Number *'
          />
          <Input
            ref={EMPLOYEEID}
            defaultValue={modal.code}
            label='Employee Id *'
          />
        <div className="flex align-center justify-between mt2">
          <label className="uppercase">
            Is Super User
          </label>
        <Switch state={isSuper} label='Super User' onChange={toggleSuper}/>
        </div>
        {/* only show if not super */}
        <Seperator />
        {
          !isSuper && (CONFIG.origin.code === 'CLRTRP' || CONFIG.origin.code === 'CLT-STG') ?
          <div className="flex horizontal">
            <div className={CtRolesModal['FINANCE']?'chip-active':'chip'} onClick={()=>{
            if (CtRolesModal['FINANCE']){
              setRoles([]);
              setCtRolesModal({
                FINANCE:false,
                SALES:false,
                OPERATIONS:false,
              })
              }
              else{
                setRoles([...ctRoles['FINANCE']])
                setCtRolesModal({
                  FINANCE:true,
                  SALES:false,
                  OPERATIONS:false,
                })}}}>FINANCE
            </div>

            <div className={CtRolesModal['SALES']?'chip-active':'chip'} onClick={()=>{
            if (CtRolesModal['SALES']){
              setRoles([]);
              setCtRolesModal({
                FINANCE:false,
                SALES:false,
                OPERATIONS:false,
              })
              }
              else{
                setRoles([...ctRoles['SALES']])
                setCtRolesModal({
                  FINANCE:false,
                  SALES:true,
                  OPERATIONS:false,
                })}}}>SALES
            </div>

            <div className={CtRolesModal['OPERATIONS']?'chip-active':'chip'} onClick={()=>{
            if (CtRolesModal['OPERATIONS']){
              setRoles([]);
              setCtRolesModal({
                FINANCE:false,
                SALES:false,
                OPERATIONS:false,
              })
              }
              else{
                setRoles([...ctRoles['OPERATIONS']])
                setCtRolesModal({
                  FINANCE:false,
                  SALES:false,
                  OPERATIONS:true,
                })}}}>OPERATIONS
            </div>
          </div> : undefined
        }
        {!isSuper ?
              <ChipsSelect
                options={Object.values(userRoles)}
                label='Select Roles *'
                type='multiple'
                selected={handleSelected()}
                onSelect={setRoles}
              />
            : undefined
        }
        <Seperator />
        {
          CONFIG.origin.code !== 'YESUAT' && CONFIG.origin.code!=='YES-PROD' &&
          <>
          <ChipsSelect
            options={Object.values(RESTRICT)}
            label='Restrict Views'
            type='multiple'
            selected={restrict}
            onSelect={setRestrict}
            />
          <Seperator />
          </>
        }
           <Tags 
              tags={selectedClient} 
              setTags={setSelectedClient} 
           />
         {CONFIG.origin.code !== 'YESUAT' && CONFIG.origin.code!=='YES-PROD' && <><Button onClick={()=>{setAddClient(true)}} className='btn btn-black btn-sm'>
               Add Client (optional)
           </Button><Seperator/></>} 


          <Button onClick={updateUser} className='btn-primary mt5'>
            + Update Admin
          </Button>
        </SidePane>
      )

      
      }
      {
      addClient? 
          <SelectClient 
            allClients={allClients} 
            onNext={(items)=>{
              setSelectedClient(items);
              setAddClient(false);
            }} 
            selectedClients={selectedClient}
            onClose={()=>setAddClient(false)} 
            /> : 
          null
        
      }
      <div className='relative'>
        <div
          className='flex justify-between'
        >
          <div style={{width:'40%'}}>
            <SearchBox
              onChange={(q) => {
                setSearch({
                  ...search,
                  q: q && q.length > 0 ? q : undefined,
                  page: 0,
                });
              }}
              containerClass=''
              style={{
                zIndex: 2,
              }}
              placeholder='Enter your search criteria'
            />
          </div>
          <div c>
            <Button loading={adminDataLoading} className="btn btn-black" onClick={downloadAdminData} ><i className="fa fa-download mr1"/>Download Data</Button>
          </div>
        </div>
        <ScrollableDynamicTable
          setSearch={(search) => {
            setSearch({
              ...search,
              page: 0,
            });
          }}
          search={search}
          setPage={(page) => {
            setSearch({
              ...search,
              page,
            });
          }}
          data={{
            current: Data.currentPage,
            total: Data.totalPages,
            items: Data.items.map((item) => {
              return {
                content: item,
                render: [
                  {
                    children: (
                      <div
                        onClick={() => {
                          History.push(`/app/tasks/${item.id}`);
                        }}
                      >
                        <p className='text'>{item.name}</p>
                      </div>
                    ),
                    weight: 2,
                  },
                  {
                    weight: 1,
                    title: item.email,
                  },
                  {
                    weight: 1,
                    buttons: [
                      {
                        title: "Edit",
                        className: "btn-secondary btn-sm",
                        before: <EditIcon size='small' />,
                        onClick: () => {
                          click(item);
                        },
                      },
                    ],
                    actions: [
                      {
                        title: "Quick Actions",
                        actions: [
                          {
                            title: "Edit",
                            before: <EditIcon size='small' />,
                            onClick: () => {
                              click(item);
                            },
                          },
                          {
                            title: "Deactivate",
                            before: <DeleteIcon size='small' />,
                            onClick: () => {
                              deleteItem(item);
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              };
            }),
          }}
          headers={[
            {
              weight: 2,
              title: "Name",
              sort: "name",
            },
            {
              weight: 1,
              title: "Email",
              sort: "email",
            },
            {
              weight: 1,
              title: "Actions",
              style: {
                justifyContent: "end",
              },
            },
          ]}
        />
      </div>
    </div>
  );
}
