import { get, post } from "App/Network/Axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Button, InlineTable, Input, Pages, TOAST, Modal } from "retro/index";

export default function EscalationMatrix() {
  const { id } = useParams();
  const [matrixModal, setMatrixModal] = useState(false);
  const KEY = useRef(),
    VALUE = useRef();
  const [matrixData, setMatrixData] = useState({
    loading: true,
  });

  // this function is used to fetch the matrixData i.e all key value pairs
  const load = useCallback(() => {
    get(`/clients/escalationMatrix/${id}/get`, (e, r) => {
      if (e) {
        TOAST.error("There is some issue while fetching matrix data.");
        return;
      } else {
        setMatrixData({
          ...r,
          loading: false,
        });
      }
    });
  }, [id]);


  useEffect(() => {
    if (matrixData.loading) {
      load();
    }
  }, [matrixData.loading, load]);


  // this function is to add esaclation Matrix pair
  const addPair = ({ key, value }) => {
    post(`/clients/escalationMatrix/${id}/add`, { key, value }, (e, r) => {
      if (r) {
        load();
        setMatrixModal(false);
        TOAST.success("Matrix pair added successfully.");
      }
      if (e) {
        TOAST.handleError(e);
      }
    });
  };

  return (
    <>
      <Pages.Title
        title="Escalation Matrix"
        description="Manage escalation matrix for this client."
      />
      <div className="border-sm pd4 w-50 mt4 rounded-md">
        <InlineTable
          maxWidth={550}
          headers={["Key", "Value", "Actions"]}
          rows={Object.keys(matrixData?.map || []).map((value) => {
            return [
              value,
              matrixData.map[value],
              <Button
                onClick={() => {
                  post(
                    `clients/escalationMatrix/${id}/remove`,
                    { key: value },
                    (e, r) => {
                      if (r) {
                        TOAST.success("Removed Pair");
                        load();
                      }
                      if (e) {
                        TOAST.handleError(e);
                      }
                    }
                  );
                }}
                margin="mt1 mb1"
                className="btn-link btn-sm btn-link-danger"
              >
                - Delete
              </Button>,
            ];
          })}
        />
        <Button
          onClick={() => {
            setMatrixModal(true);
          }}
          className="btn-link"
        >
          + Add Pair
        </Button>
      </div>

      {/* this modal opens when user clicks on add pair  */}
      {matrixModal && (
        <Modal
          onClose={() => {
            setMatrixModal(false);
          }}
          button="Add Pairs"
          onSubmit={() => {
            let key = KEY.current.value;
            let value = VALUE.current.value;
            if (!key) {
              TOAST.error("Enter a valid key");
              return;
            }
            if (!value) {
              TOAST.error("Enter a valid value");
              return;
            }
            addPair({ key, value });
          }}
          title="Add Pairs"
          description="Enter details to add a new pairs"
        >
          <Input type="text" ref={KEY} label="Enter key details" morph={true} />
          <Input
            type="text"
            ref={VALUE}
            label="Enter value details"
            morph={true}
          />
        </Modal>
      )}
    </>
  );
}
