import React, { useRef, useState } from "react";
import { Button, Input, NavDivider, Navigation, TOAST } from "retro/index";
import { post } from "App/Network/Axios";
// import HotelSearchInput from 'App/Components/Inputs/HotelSearchInput/HotelSearchInput';
import SearchCities from "App/Components/Inputs/HotelSearchInput/HotelSearchInput";

export default function MoveToDifferentCity() {
  const TRANSID = useRef();
  const CRN = useRef();
  const INV = useRef();
  const GST = useRef();
  const SVC = useRef();
  const REMARKS = useRef();
  const [loading, setLoading] = useState(false);
  const [Citi, setCiti] = useState({ country: "india" });
  return (
    <div>
      <Navigation backUrl={"back"} title="Move to Different city" />
      <NavDivider />
      <Input
        ref={TRANSID}
        type="text"
        label="Transaction Id"
        placeholder="Transaction Id"
      />
      <Input
        ref={GST}
        type="text"
        label="New Gst Number"
        placeholder="New Gst Number"
      />
      <Input
        ref={CRN}
        type="text"
        defaultValue="CRFN"
        label="Credit Note Prefix For Old Invoice"
        placeholder="Credit Note Prefix For Old Invoice"
      />
      <Input
        ref={INV}
        defaultValue="INC"
        type="text"
        label="Invoice Prefix  For New Invoice"
        placeholder="Invoice Prefix"
      />
      <Input
        ref={SVC}
        type="text"
        defaultValue="SNC"
        label="Service Prefix"
        placeholder="Service Prefix"
        className='mb1'
      />
      {/* search city */}
      <label>Select City</label>
      <SearchCities
        recentCities={true}
        hide={true}
        className="mt1"
        api="/meta/cities/search"
        onSelect={(city) => {
          setCiti(city);
        }}
        selected={Citi}
        placeholder={"Search City"}
        renderResult={(item) => (
          <div>
            <p>
              {item?.name}
              {item.country === "India" ? `, ${item?.state}` : ""},{" "}
              {item?.country}
            </p>
          </div>
        )}
      />
      {/* updates state after selecting city */}
      <Input
        label="Hotel State"
        className="mt2"
        defaultValue={Citi.state}
        disabled={true}
        placeholder="Select State"
      />
      <Input ref={REMARKS} type="text" label="Remarks" placeholder="Remarks" />
      <Button
        onClick={() => {
          let transId = TRANSID.current.value;
          let remarks = REMARKS.current.value;
          let crn = CRN.current.value;
          let svc = SVC.current.value;
          let gstin = GST.current.value;
          let inv = INV.current.value;
          let city = Citi.name;
          let state = Citi.state;
          if (!transId) {
            TOAST.error("Enter a valid trans id");
            return;
          }
          if (!svc) {
            TOAST.error("Enter a valid service charges");
            return;
          }
          if (!inv) {
            TOAST.error("Enter a valid invoice number");
            return;
          }
          if (!crn) {
            TOAST.error("Enter a valid credit number");
            return;
          }
          if (!gstin) {
            TOAST.error("Enter a valid gstin");
            return;
          }
          if (!city) {
            TOAST.error("Enter city");
            return;
          }
          if (!state || state === "NONE") {
            TOAST.error("Enter state");
            return;
          }
          setLoading(true);
          let data = {
            transId,
            remarks,
            crn,
            svc,
            gstin,
            inv,
            city,
            state:state?.trim().replaceAll(" ","").toUpperCase(),
          };
          post(
            // `/meta/${transId}/hotel/reseller/entity`,
            `/meta/${transId}/hotel/reseller/entity/city`,
            data,
            (err, res) => {
              setLoading(false);
              if (res) {
                TOAST.success("Regeneration done successfully");
                TRANSID.current.value = "";
                REMARKS.current.value = "";
              } else {
                TOAST.handleError(err);
              }
            }
          );
        }}
        className="btn-black btn-primary"
        margin="pd2 mt4"
        loading={loading}
      >
        Regenerate
      </Button>
    </div>
  );
}
