import React, {useRef, useState} from 'react'
import {Button, Input, KeyValue, Modal, Seperator, SidePane, TOAST} from "../../../../retro";
import Moment from "moment";
import {post} from "App/Network/Axios";
import ApprovalView from "./ApprovalView";
import useConfigHook from 'App/Hooks/useConfigHook';

export default function ViewRequest({onClose, item}) {
	const [modal, setModal] = useState(false)
	const REMARKS = useRef()
	const config = useConfigHook();
	return (
		<>
			{
				modal && <Modal onClose={() => {
					setModal(false)
				}} title="Decline Request" description="Enter details to decline request">
					<Input type="text" ref={REMARKS} label="Remarks" placeholder="Enter Remarks"/>
					<Button onClick={() => {
						let remarks = REMARKS.current.value;
						if (!remarks) {
							TOAST.error("Enter a valid remarks")
							return;
						}
						post(`/approvals/${item.queueId}/decline`, {remarks}, (e, r) => {
							if (r) {
								TOAST.success("Request declined successfully")
								onClose()
							} else {
								TOAST.handleError(e)
							}
						})
					}} className="btn-black" margin="mt4">
						Decline Request
					</Button>
				</Modal>
			}
			<SidePane actions={item.status === "QUEUED" && item.raisedBy.adminEmail !== config.email && (
				<div className="flex horizontally center-vertically">
					<Button onClick={() => {
						post(`/approvals/${item.queueId}/approve`, {}, (e, r) => {
							if (r) {
								TOAST.success("Request approved successfully")
								onClose()
							} else {
								TOAST.handleError(e)
							}
						})
					}} className="btn btn-black mr1 flex-1" margin="">
						Approve
					</Button>
					<Button onClick={() => {
						setModal(true)
					}} className="btn btn-danger ml1 flex-1" margin="">
						Decline
					</Button>
				</div>
			)} title="Approve Request" description="Kindly review below details and approve" onClose={onClose}>
				<div className="text-small mt4">
					<h3 className="mb2">
						Request Details
					</h3>
					<KeyValue title="Created On" value={Moment(item.createdAt).format("DD MMM YYYY HH:mm")}/>
					<KeyValue title="Type" value={item.type}/>
					<Seperator/>
					<h3 className="mb2">
						Raised By
					</h3>
					<KeyValue title="Raised By" value={item.raisedBy.adminName}/>
					<KeyValue title="Raised By(Email Address)" value={item.raisedBy.adminEmail}/>
					<Seperator/>
					<h3 className="mb2">
						Raised For
					</h3>
					<KeyValue title="Raised For" value={item.raisedFor?.name}/>
					<KeyValue title="Raised For(Email Address)" value={item.raisedFor?.email}/>
					<KeyValue title="Raised For(Code)" value={item.raisedFor?.code}/>
					<Seperator/>
					{item?.status==="TRIGGERED"&&(
						<><h3 className="mb2">
						   Approved By
					</h3>
					<KeyValue title="Approved By" value={item.verifiedBy?.adminName}/>
					<KeyValue title="Approved By(Email Address)" value={item.verifiedBy?.adminEmail}/>
					<Seperator/>
						</>
					)}
                   {item?.status==="FAILED" &&(
						<><h3 className="mb2">
						   Declined By
					</h3>
					<KeyValue title="Declined By" value={item?.verifiedBy?.adminName}/>
					<KeyValue title="Declined By(Email Address)" value={item?.verifiedBy?.adminEmail}/>
					<KeyValue title="Remark" value={item?.failedReason?item?.failedReason:"-"}/>
					<Seperator/>
						</>
					)}
					<h3 className="mb2">
						Details
					</h3>
					<ApprovalView data={item}/>
					{((window.origin.includes("yesleap") || window.origin.includes('yespayleaf')) && item.type==='CLIENT_VA_UPDATE' ) && <div><KeyValue title="Requested Account Number" value={JSON.parse(item.request).account}/>
										<KeyValue title="Requested IFSC" value={JSON.parse(item.request).ifsc}/>
										<KeyValue title="Current Account Number" value={JSON.parse(item.request)?.oldAccount || '-'}/>
										<KeyValue title="Current IFSC" value={JSON.parse(item.request)?.oldIfsc || '-'}/>
					</div>
					}
					</div>
			</SidePane>
		</>
	)
}
