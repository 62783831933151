/**
 * Author[Shubham Rawat]
 * Version 1.0.0
 * 11th December | 1.0.0 | Shubham Rawat | created the import form for bus
 */
import React, { useEffect, useRef, useState } from 'react';
import {
  Attachment,
  Button,
  DateRange,
  FileInput,
  Input,
  NavDivider,
  Navigation,
  SelectInput,
  TOAST,
  Timeinput,
} from 'retro/index';
import Moment from 'moment/moment';
import { get, post } from 'App/Network/Axios';
import { AddTravellerModal } from '../ImportTrain';
import { PrefferedGST } from '../ImportHotel';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment/moment';

const ImportBus = () => {
  const [formData, setFormData] = useState({
    prefferedGst: undefined,
    prefferedGstName: undefined,
    clientCode: undefined,
    clientId: undefined,
    city: undefined,
    email: undefined,
    allGsts: [],
  });

  const EMAIL = useRef();
  const GSTIN = useRef();
  const ACCOUNT = useRef();
  const AMOUNT = useRef();
  const PG = useRef();
  const BUSDATE = useRef();
  const PNR = useRef();
  const FILE = useRef();
  const BOARDING = useRef();
  const BOARDING_TIME = useRef();
  const DROPING_TIME = useRef();

  const DROPING = useRef();
  const HISTORY = useHistory();
  const TRIPID = useRef();

  const [accounts, setAccounts] = useState([]);
  const [accountType, setAccountType] = useState(undefined);
  const [travellers, setTravellers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(0);
  const [file, setFile] = useState(undefined);

  // set dropdowns as per the cleint
  useEffect(() => {
    setAccounts([]);
    if (formData.clientCode) {
      // get the list of accounts for the client
      get(`/accounts/${formData.clientId}`, (e, r) => {
        if (r) {
          setAccounts(r.items);
          setAccountType(undefined);
        }
      });
    }
  }, [formData.clientCode]);

  //   function to add booking import
  const importBooking = () => {
    let email = EMAIL.current.value;
    let amount = AMOUNT.current.value;
    let account = ACCOUNT?.current?.value;
    let gstin = GSTIN.current.value;
    let pgId = PG?.current?.value;

    let busDate = BUSDATE.current.value;
    let busPnr = PNR.current.value;
    let boardingPoint = BOARDING.current.value;
    let droppingPoint = DROPING.current.value;
    let boardingTime = moment(BOARDING_TIME.current.value, 'HH:mm').valueOf();
    let dropingTime = moment(DROPING_TIME.current.value, 'HH:mm').valueOf();

    if (account === 'NONE') {
      TOAST.error('Please select a deposit account');
      return;
    }

    if (!email) {
      TOAST.error('Enter a valid amount');
      return;
    }
    if (!busPnr) {
      TOAST.error('Enter a PNR');
      return;
    }
    if (!gstin) {
      TOAST.error('Select a GSTIN');
      return;
    }
    if (!account) {
      TOAST.error('Select an account');
      return;
    }
    if (!boardingPoint) {
      TOAST.error('Please add boarding point');
      return;
    }
    if (!droppingPoint) {
      TOAST.error('Please add dropping point');
      return;
    }
    if (!boardingTime) {
      TOAST.error('Please add boarding time');
      return;
    }
    if (!dropingTime) {
      TOAST.error('Please add dropping time');
      return;
    }
    if (!busDate) {
      TOAST.error('Enter a bus date');
      return;
    }

    if (!amount || amount < 100) {
      TOAST.error('Enter a valid amount');
      return;
    }

    setLoading(true);
    busDate = Moment(busDate).format('DD-MM-YYYY');
    let data = {
      id: `import_bus_(${Moment().unix()})`,
      client: formData.clientCode,
      email,
      date: busDate,
      amount,
      gstin,
      account,
      pgId,
      tripId:TRIPID?.current?.value,
      ticket: {
        pnr: busPnr,
        amount,
        travellers: travellers,
        ticket: file,
        bus: {
          boarding: [
            {
              name: boardingPoint,
              time: boardingTime,
            },
          ],
          dropping: [
            {
              name: droppingPoint,
              time: dropingTime,
            },
          ],
        },
      },
    };
    post(`/meta/bus/import`, data, (e, r) => {
      setLoading(false);
      if (e) {
        TOAST.handleError(e);
      } else {
        TOAST.success('Bus imported successfully');
        if (r?.refId) {
          HISTORY.push(`/app/apps/meta/transaction/${r.refId}`);
        }
        EMAIL.current.value = '';
        setPrice(0);
      }
    });
  };

  return (
    <div>
      <Navigation backUrl={'back'} title="Import Bus Booking" />
      <NavDivider />
      {!formData.prefferedGst ? (
        <PrefferedGST data={formData} setData={setFormData} category="bus" />
      ) : (
        <>
          <div className="flex horizontally center-vertically mt1">
            <SelectInput
              placeholder="Select"
              hidePlaceholder={false}
              options={[
                ...accounts.map((item) => {
                  return {
                    label: `${item.title} (INR ${item.balance})`,
                    value: item.accountId,
                  };
                }),
                { label: 'RAZORPAY', value: 'RAZORPAY' },
              ]}
              onChange={setAccountType}
              ref={ACCOUNT}
              label="Select Deposit Account"
              className="mr1 flex-1"
            />
            <SelectInput
              placeholder="Select"
              hidePlaceholder={false}
              options={[
                {
                  label: `${formData.prefferedGst} - ${formData.prefferedGstName} (Preffered GST)`,
                  value: formData.prefferedGst,
                },
                ...formData.allGsts.map((gst) => {
                  return {
                    label: `${gst.gstin} - ${gst.name}`,
                    value: gst.gstin,
                  };
                }),
              ]}
              defaultValue={formData.prefferedGst}
              ref={GSTIN}
              label="Select the GSTIN"
              className="flex-1"
            />
          </div>
          {/* https://dice-antrepriz.atlassian.net/browse/CTOOO-71 */}
          <div className="flex horizontally center-vertically mt2">
            <Input 
            type='text' 
            ref={TRIPID} 
            label="Enter Trip Id (Optional)"
            placeholder="Enter trip id"
            className="flex-1"
            />
          </div>
          {accountType === 'RAZORPAY' && (
            <Input
              ref={PG}
              type="text"
              label="Enter Pg Id"
              placeholder="Enter Pg Id"
              className="mb2"
            />
          )}
          <div className="flex horizontally center-vertically mt1">
            <Input
              ref={EMAIL}
              type="text"
              label="Requestor Email"
              placeholder="Email Id"
              className="mr1 flex-1"
              defaultValue={formData.email}
            />
            <Input
              ref={PNR}
              type="text"
              label="PNR"
              placeholder="PNR"
              className="ml1 flex-1"
            />
          </div>
          <div className="flex horizontally center-vertically mt1">
            <Input
              ref={BOARDING}
              label="Boarding Point"
              placeholder="Boarding Point"
              className="mr1 flex-1"
            />
            <Input
              ref={DROPING}
              label="Droping Point"
              placeholder="Droping Point"
              className="ml1 flex-1"
            />
          </div>
          <div className="flex horizontally center-vertically mt1">
            <Timeinput
              ref={BOARDING_TIME}
              label="Boarding Time"
              placeholder="Boarding Time"
              className="mr1 flex-1"
            />
            <Timeinput
              ref={DROPING_TIME}
              label="Droping Time"
              placeholder="Droping Time"
              className="ml1 flex-1"
            />
          </div>
          <div className="flex horizontally center-vertically mt1">
            <DateRange
              ref={BUSDATE}
              label="Bus Date"
              placeholder="Bus Date"
              className="mr1 flex-1"
            />
            <Input
              ref={AMOUNT}
              type="number"
              label="Amount"
              onChange={() => setPrice(AMOUNT.current?.value || 0)}
              placeholder="Amount"
              className="ml1 flex-1"
            />
          </div>

          {/* modal to add multiple travellers */}
          <AddTravellerModal
            travellers={travellers}
            setTravellers={setTravellers}
          />
          {/* ticket attachment */}
          <div className="mt2">
            <FileInput
              label="Attach the copy of the ticket"
              updateFiles={(url) => {
                setFile(url);
              }}
              ref={FILE}
            />
            <Attachment url={file} onDelete={() => setFile(undefined)} />
          </div>
          <div className="flex horizontally center-vertically mt4 ">
            <Button
              onClick={() => setFormData({})}
              className="btn-black btn-primary mr4"
              margin="pd2"
            >
              <i className="fa-solid fa-arrow-left mr1" />
              Previous
            </Button>
            <Button
              onClick={importBooking}
              className="btn-black btn-primary"
              margin="pd2"
              loading={loading}
            >
              Confirm Booking (INR {price})
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default ImportBus;
