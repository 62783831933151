import React from "react";
import AllLogs from "./AllLogs";
import { Route, Switch } from "react-router-dom";
import ROLES from "App/Constants/Roles";
import RBAC from "App/HOCs/RBAC";
import { Wrapper } from "retro/index";

const Logs = () => {
  return (
    <RBAC role={ROLES.APP} renderNoAccess>
      <div className="flex h-100h w-100">
        <Switch>
          <Route path="/app/logs" exact>
            <Wrapper>
              <AllLogs />
            </Wrapper>
          </Route>
        </Switch>
      </div>
    </RBAC>
  );
};

export default Logs;