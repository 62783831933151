import React, {useEffect, useRef, useState} from 'react'
import {Route, Switch, useParams} from "react-router-dom";
import {get, post} from "../../../Network/Axios";
import {Button, Input, KeyValue, Loader, Modal, Navigation, SelectInput, Tabs, TOAST} from "../../../../retro";
import Ledger from './Ledger'
import RemoteLedger from './RemoteLedger'
import Bookings from './Bookings'
import Settings from './Settings'
import QRCode from "react-qr-code";
import {useSelector} from "react-redux";
import BulkRecharge from './BulkRecharge';
import DownloadLedgerReport from './DownloadLedgerReport';
// import useRoleHook from 'App/Hooks/useRoleHook';
import DownloadLedgerStatementReport from './DownloadLedgerStatementReport';

export default function index() {
	const {id,clientId} = useParams();
	const AMOUNT = useRef();
	const REMARKS = useRef()
	const CREDITTYPE = useRef()
	const {code} = useSelector(data=>({code:data?.auth?.config?.origin?.code||""}));

	const [modal,setModal] = useState(false)
	const [limit,setLimit] = useState(false);
	const [bulkRecharge, setBulkRecharge]=useState(false);
	const [openDownloadModal, setOpenDownloadModal]=useState(false);
	const [statementDownloadModal,setStatementDownloadModal] = useState(false);
	// const finance_write_access = useRoleHook('FINANCE_WRITE');
	const [Data,setData] = useState({
		type : "CREDIT",
		loading:true
	})

	const getAccountDetails=()=>{
		get(`/accounts/account/${id}`,(e,r)=>{
			if(r){
				setData({
					...r,
					loading: false
				})
			}
		})
	}
	useEffect(()=>{
		getAccountDetails();
	},[id])
	if(Data.loading){
		return (<Loader/>)
	}

	// show name with refrence id, only when refrence id exists
	const title = Data.attrs?.referenceId
  ? `${Data.name} - ${Data.attrs.referenceId || "-"}`
  : `${Data.name}`;
console.log("Data",Data)
	return (
		<>
			<Navigation backUrl={'back'} title={title} chips={['Account Details', Data.clientName,Data.name]} description={Data.clientName}/>
			<div style={{
				marginTop:16,
				marginBottom:16,
				maxHeight:'calc(100vh - 300px)',
			}} className="border-sm pd3 rounded-sm relative">
				<div className="flex-wrap flex">
					<div className="flex-1">
					<div>{Data.account}</div>
					<div>{Data.ifsc}</div>
					</div>
					<div className="flex-1">
						<KeyValue title="Balance" value={`INR ${Data.balance}`}/>
					</div>
					<div className="flex-1">
						{
							// Data.type==="CREDIT"&& finance_write_access===true &&
							Data.type==="CREDIT"&&
							<KeyValue title="Credit Limit" value={`INR ${Data.limit}`}/>
						}
					</div>
				</div>
				{
					code!=="YESUAT" && code!=='YES-PROD'&&
					<div className="flex flex-wrap">
						{/* {finance_write_access===true && 
						<div>
						<Button onClick={()=>{
							setModal(true)
						}} className="btn-primary btn-sm mr2">
							+ Recharge
						</Button>
						<Button onClick={()=>{
							setBulkRecharge(true)
						}} className="btn-primary btn-sm mr2">
						<i className='fa fa-upload mr1'/>Bulk Recharge
						</Button>
						</div>
						} */}

						<Button onClick={()=>{
							setModal(true)
						}} className="btn-primary btn-sm mr2">
							+ Recharge
						</Button>
						<Button onClick={()=>{
							setBulkRecharge(true)
						}} className="btn-primary btn-sm mr2">
						<i className='fa fa-upload mr1'/>Bulk Recharge
						</Button>
						
						<Button onClick={()=>{
							setOpenDownloadModal(true)
						}} className="btn-primary btn-sm mr2">
						<i className='fa fa-download mr1'/>Download Data
						</Button>
						{
							code !== 'YESUAT' &&  
							<Button onClick={()=>{
								setStatementDownloadModal(true)
							}} className="btn-primary btn-sm mr2">
							<i className='fa fa-download mr1'/>Download Statement
							</Button>
						}
						{
							Data.type==="CREDIT"&& <Button onClick={()=>{
								setLimit(true)
							}}  className="btn-secondary btn-sm">
								+ Enhance Limit
							</Button>
						}
					</div>
				}
			</div>
			<div style={{maxWidth:600}}>
				<Tabs className="mb0" tabs={[
					{
						title: "Wallet Ledger",
						path: "/app/accounts/"+clientId+"/" + id ,
						type:'equals'
					},
					{
						title: "Bookings",
						href: "/app/accounts/"+clientId+"/" + id + "/bookings",
					},
					{
						title: "Settings",
						href: "/app/accounts/" +clientId+"/"+ id + "/settings",
					}
				]}/>
			</div>
			<Switch>
				<Route path="/app/accounts/:clientId/:id" exact>
					{code === 'YESUAT' || code === 'YES-PROD' ? <RemoteLedger /> : <Ledger />}
				</Route>
				<Route path="/app/accounts/:clientId/:id/bookings" exact>
					<Bookings/>
				</Route>
				<Route path="/app/accounts/:clientId/:id/settings" exact>
					<Settings/>
				</Route>
			</Switch>

			{
				modal&&
				<Modal title="Recharge Account" description="Enter details below to recharge account" onClose={()=>{
					setModal(false)
				}}>
					<Input morph={true} label="Amount" placeholder="Enter Amount" ref={AMOUNT}/>
					<Input morph={true} label="Details" placeholder="Enter remarks like UTR or Transaction Id" ref={REMARKS}/>
					<Button debounce={true} onClick={()=>{
						let amount = AMOUNT.current.value;
						let remarks = REMARKS.current.value;
						amount = Number(amount)
						post(`/accounts/account/${clientId}/${id}/add`,{amount,remarks},(e,r)=>{
							if(r){
								window.location.reload();
								TOAST.success("Account recharged successfully!!")
								setModal(false);
							}else{
								TOAST.handleError(e)
							}
						})
					}} className="btn-primary btn-max mt3">
						+ Recharge Account
					</Button>
				</Modal>
			}
			{
			limit&&
			<Modal title="Manage Account Limit" description="Enter details below to manage account limit" onClose={()=>{
				setLimit(false)
			}}>
				<Input morph={true} min={0} label="Amount" type="number" placeholder="Enter Amount" ref={AMOUNT}/>
				{ Data.type==="CREDIT" && 
					<SelectInput
						options={[
							{ label: "Credit Extend", value: "CREDITEXTENDED" },
							{ label: "Credit Reverse", value: "CREDITREVERSE" },
						]}
						ref={CREDITTYPE}
						type='number'
						label='Type'
						hidePlaceholder={true}
						className='mr1 flex-1'
					/>
				}
				<Input morph={true} label="Summary" placeholder="Enter remarks like UTR or Transaction Id" ref={REMARKS}/>
				<Button debounce={true} onClick={()=>{
					let amount =AMOUNT.current.value;
					if(amount<0){
						TOAST.error("Enter Valid Amount");
						return;
					}
					amount = Data.type==="CREDIT" && CREDITTYPE.current.value==="CREDITEXTENDED"?amount:-1*amount;
					let remarks = REMARKS.current.value;
					remarks = Data.type==="CREDIT"?CREDITTYPE.current.value +" " +remarks:remarks;
					amount = Number(amount)
					post(`/accounts/account/${clientId}/${id}/limit`,{amount,remarks},(e,r)=>{
						if(r){
							window.location.reload();
							TOAST.success("Account recharged successfully!!")
							setLimit(false);
						}else{
							TOAST.handleError(e)
						}
					})
				}} className="btn-primary btn-max mt3">
					+ Recharge Account
				</Button>
			</Modal>
		}
      {openDownloadModal && 
        <Modal
          title="Download DA Report"
          description="Using custom date range"
          onClose={() => setOpenDownloadModal(false)}
        >
          <DownloadLedgerReport id={id} />
        </Modal>
      }
	  {statementDownloadModal && 
        <Modal
          title="Download DA Statement Report"
          description="Using custom date range"
          onClose={() => setStatementDownloadModal(false)}
        >
          <DownloadLedgerStatementReport id={id} />
        </Modal>
      }
		{
			bulkRecharge && <BulkRecharge setBulkRecharge={setBulkRecharge} />
		}
		</>
	)
}
