
// https://dice-antrepriz.atlassian.net/browse/CTOOO-142

import React, { useRef, useState } from "react";
import { Button, Input, NavDivider, Navigation, TOAST } from "retro/index";
import { post } from "App/Network/Axios";

export default function index() {
  const TRANSID = useRef();
  const INV = useRef();
  const CRN = useRef();
  const REMARKS = useRef();
  const [loading, setLoading] = useState(false);


  return (
    <div>
      <Navigation backUrl={"back"} title="Update HSN code for hotel" />
      <NavDivider />
      <Input
        ref={TRANSID}
        type="text"
        label="Transaction Id"
        placeholder="Transaction Id"
      />
      <Input
        ref={INV}
        type="text"
        defaultValue="INC"
        label="Invoice number Prefix"
        placeholder="Invoice number Prefix"
      />
      <Input
        ref={CRN}
        type="text"
        defaultValue="CRFN"
        label="Credit Note Prefix"
        placeholder="Credit Note Prefix for Old Service Charge"
      />
      <Input ref={REMARKS} type="text" label="Remarks" placeholder="Remarks" />
      <Button
        onClick={() => {
          let transId = TRANSID.current.value;
          let inv = INV.current.value;
          let remarks = REMARKS.current.value;
          let crn = CRN.current.value;
          if (!transId) {
            TOAST.error("Enter a valid trans id");
            return;
          }
          if (!inv) {
            TOAST.error("Enter a valid INV id");
            return;
          }
          if (!crn) {
            TOAST.error("Enter a valid credit number");
            return;
          }
          if (!remarks) {
            TOAST.error("Enter a valid Remarks");
            return;
          }
          setLoading(true);
          let data = {
            remarks,
            crn,
            inv
          }
          post(
            `/meta/${transId}/hotel/agency/reseller/lowerGst`,
            data,
            (err, res) => {

              setLoading(false);
              if (res) {
                TOAST.success("Regeneration done successfully");
                TRANSID.current.value = "";
                REMARKS.current.value = "";
              } else {
                TOAST.handleError(err);
              }
            }
          );
        }}
        className="btn-black btn-primary"
        margin="pd2 mt4"
        loading={loading}
      >
        Regenerate
      </Button>
    </div>
  );
}
