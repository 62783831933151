import React, {useEffect, useRef, useState} from 'react'
import {
	Attachment,
	Button,
	Input,
	KeyValue,
	Loader,
	Modal,
	SearchInput,
	SelectInput,
	Seperator,
	SidePane,
	TOAST as Alert,
	TOAST
} from "../../../../../retro";
import {get, post} from "../../../../Network/Axios";
import Moment from "moment";
import SectionMessage from "@atlaskit/section-message";
import Hotel from "./Hotel";

export default function ViewRequest({id,onClose}) {
	const [data,setData] = useState({
		status : "LOADING"
	})
	const [txn,setTxn] = useState(undefined)
	const [decline,setDecline] = useState(false);
	const [DRemarks,setDRemarks] = useState(undefined);
	const [change,setChange] = useState(false)
	const [hotel,setHotel] = useState(false)
	const [assignToOtherModal, setAssignToOtherModal] = useState(false);
	const [selectedAdmin, setSelectedAdmin] = useState();

	const VOUCHER = useRef();
	const NAME = useRef();
	const ADDRESS = useRef();
	const TYPE = useRef();
	const CITY = useRef();
	const ROOMNAME = useRef();
	const ROOMINCULSIONS = useRef();
	const CANCELLATION = useRef();
	const AMOUNT = useRef();
	const PRICEPERNIGHT = useRef();
	const REMARKS = useRef()
	const PURCHASEPRICE = useRef();
	const OTHERREMARKS = useRef();


	const load  = id => {
		get(`/meta/contracted/${id}/details`, (e, r) => {
			if (r) {
				setData(r)
				if(r.transId){
					loadTxn(r.transId)
				}
			} else {
				Alert.handleError(e)
			}
		})
	}
	const loadTxn = id => {
		get(`/meta/${id}/details`,(e,r)=>{
			if(r){
				setTxn(r)
			}else{
				TOAST.handleError(e)
			}
		})
	}
	useEffect(()=>{
		load(id)
	},[id])
	if(data.status==="LOADING"){
		return (
			<SidePane  title="Hotel Request" description="Details of this hotel request queue appears below." onClose={onClose}>
				<Loader/>
			</SidePane>)
	}
	let gstSlab = (pricePerNight) => {
		if(pricePerNight<8500){
			return .12;
		}else{
			return .18;
		}
	}
	return (
		<SidePane  preview={<div >
			<div className="pd4 border mt4 mb6" style={{width:425}}>
				<h3 className="fw-bold">
					Hotel Request
				</h3>
				<p>
					Details of this hotel request appears below.
				</p>
				<div className="mt4">
					<KeyValue title="Booking Id" value={data.bookingId}/>
				</div>
				<div className="flex mt2 horizontally center-vertically">
					<KeyValue title="Client Code" value={data.client.clientCode}/>
					<KeyValue title="Status" value={data.status}/>
				</div>
				<Seperator margin={2}/>
					<KeyValue title="Check In Instruction" value={data.remarks}/>
				<Seperator margin={2}/>
				<p className="fw-bold">Search Request</p>
				<div className="flex mt2 horizontally center-vertically">
					<KeyValue title="Check In" value={Moment(data.searchRequest.dates.in,"DD-MM-YYYY").format("DD-MMM-YYYY")}/>
					<KeyValue title="Check Out" value={Moment(data.searchRequest.dates.out,"DD-MM-YYYY").format("DD-MMM-YYYY")}/>
				</div>
				<p className="fw-bold">
					Rooms
				</p>
				{
					data.searchRequest.rooms.map((item,index)=>{
						return (
							<div className="mt1" key={index}>
								{index+1}) Adult {item.adult} Child {item.child} Ages {JSON.stringify(item.childAges)}
							</div>
						)
					})
				}
				<Seperator margin={2}/>
				<div className="mt2">
					<p className="fw-bold">
						{data.hotel.name}
					</p>
					<p>
						{data.hotel.address}
					</p>
					<p>
						{data.hotel.city}
					</p>
				</div>
				<div className="flex mt2 horizontally center-vertically">
					<KeyValue title="Total Rooms * Total Nights" value={`${data.numberOfRooms} rooms * ${data.totalNight} nights`}/>
					<KeyValue title="Booking/Collected Amount" value={data.totalPrice}/>
				</div>
				<Seperator margin={2}/>
				<div className="flex mt2 horizontally center-vertically">
					<KeyValue title="Room Name" value={data.room.name}/>
					<KeyValue title="Meal Type" value={data.room.mealType}/>
				</div>
				<Seperator margin={2}/>
				<p className="fw-bold mb2">
					Break Up
				</p>
				{/* <div className="flex horizontally mb1">
					<div className="flex-2">
						Price Per Night Fare
					</div>
					<div className="flex-1">
						INR {data.pricePerNight}
					</div>
				</div> */}
				{/* <div className="flex horizontally mb1">
					<div className="flex-2">
						Price Per Night / Without Gst Fare
					</div>
					<div className="flex-1">
						INR {Math.ceil((data.pricePerNight/(1 + gstSlab(data.pricePerNight))))}
					</div>
				</div> */}
				<div className="flex horizontally mb1">
					<div className="flex-2">
						Total Nights
					</div>
					<div className="flex-1">
						{data.totalNight}
					</div>
				</div>
				<div className="flex horizontally mb1">
					<div className="flex-2">
						Number Of Rooms
					</div>
					<div className="flex-1">
						{data.numberOfRooms}
					</div>
				</div>
				<div className="flex horizontally mb1">
					<div className="flex-2">
						Base Fare
					</div>
					<div className="flex-1">
						INR {Math.ceil((data.totalPrice/(1 + gstSlab(data.pricePerNight))))}
					</div>
				</div>
				<div className="flex horizontally mb1">
					<div className="flex-2">
						Gst
					</div>
					<div className="flex-1">
						INR {data.totalPrice-Math.ceil((data.totalPrice/(1 + gstSlab(data.pricePerNight))))}
					</div>
				</div>

				<div className="flex horizontally">
					<div className="flex-2">
						Total Fare
					</div>
					<div className="flex-1">
						INR {data.totalPrice}
					</div>
				</div>
				<Seperator margin={2}/>
				<div>
					<p className="fw-bold">
						Travellers:
					</p>
					{
						data.guests.map((item,index)=>{
							return (
								<>
								<div key={item.email} className="mt2 flex horizontally">
									<p className="fw-bold">
										{index+1})
									</p>
									<div className="ml1 flex-2">
										<p>
											<span className='fw-bold'>Name:</span> {item.name}
										</p>
										<p className="text-small">
										  <span className='fw-bold'>Email:</span> {item.email}
										</p>
										<p className="text-small">
										<span className='fw-bold'>Mobile:</span>  {item.mobile}
										</p>
									</div>
									<div className="ml1 flex-1">
										<span className='fw-bold'>Type:</span>  {item.type}
									</div>
									<div className="ml1 flex-1">
										{item.gender}
									</div>
								</div>
								<div className='flex horizontally ml2'>
								<div className='flex-1'>
									<p className='text-small'>
									<span className='fw-bold'>Office:</span>  {item.office}
									</p>
									<p className='text-small'>
									<span className='fw-bold'>Department:</span> {item.department}
									</p>
									</div>
									<div className='flex-1'>
									<p className='text-small '>
									<span className='fw-bold'>Team:</span> {item.team}
									</p>
									<p className='text-small'>
									<span className='fw-bold'>Policy:</span> {item.policy}
									</p>
									</div>
								</div>
							</>
							)
						})
					}
				</div>
			</div>
		</div>}   title="Hotel Queue" description="Details of this hotel request queue appears below." onClose={onClose}>
			{
				data.status==="FAILED" && <div className="mt4">
					<div className="flex vertically border pd4 center-vertically">
						<p className="fw-bold">
							{data.hotel.name}
						</p>
						<p>
							{data.hotel.address}
						</p>
						<p>
							{data.hotel.city}
						</p>
					</div>
					<p className="mt2">
						Status:
					</p>
					<p>
						{data.status}
					</p>

					<p className="mt2">
						Failed Remarks:
					</p>
					<p>
						{data.failedReason}
					</p>
				</div>
			}
			{
				data.status==="COMPLETED" || data.status==="REFUNDING" || data.status==="REFUNDED"?<div className="mt4 border pd4">
					<h3 className="fw-bold">
						{data.transId}
					</h3>
					<p>
						{data.requestId}
					</p>
					<div className="mt2">
						{
							!txn ? "This booking is loading...." : <div>
								<Hotel  {...txn} id={data.transId}/>
								<div className="border mt4 pd4">
									<p className="fw-bold mb4">
										Documents
									</p>
									<div className="flex horizontally">

										{
											txn.invoice?.file ? <div className="mr2">
												<Attachment url={txn.invoice.file}/>
												<p>
													Voucher
												</p>
											</div>:undefined
										}
										{
											txn.invoice?.invoice!=="SKIPPED" ? <div className="mr2">
												<Attachment url={txn.invoice.invoice}/>
												<p>
													Invoice
												</p>
											</div>:undefined
										}
										{
											txn.invoice?.serviceInvoice!=="SKIPPED" ?
												<div className="mr2">
													<Attachment url={txn.invoice.serviceInvoice}/>
													<p>
														Service Invoice
													</p>
												</div>
												:undefined
										}
										{
											txn.invoice?.creditNote!=="PENDING" ?
												<div className="mr2">
													<Attachment url={txn.invoice.creditNote}/>
													<p>
														Credit Note
													</p>
												</div>
												:undefined
										}
										{
											txn.invoice?.cancellationCharges!=="PENDING" ?
												<div>
													<Attachment url={txn.invoice.cancellationCharges}/>
													<p>
														Cancellation Charges
													</p>
												</div>
												:undefined
										}
									</div>
								</div>
							</div>
						}
					</div>

				</div>:undefined
			}
			{
				data.status==="QUEUED"&&<div className="mt4">
					<div className="mb2">
						<SectionMessage title={data.admin.adminName}>
							<p>
								This ticket is currently handled by {data.admin.adminEmail}
							</p>
							<div className="pt2">
								<p onClick={()=>{
									post(`/meta/contracted/${id}/self.assign`,{}, (e, r) => {
										if (r) {
											load(id)
											TOAST.success("Ticket assigned to you.")
										} else {
											Alert.handleError(e)
										}
									})
								}} className="btn-link btn">
									Assign To Yourself
								</p>
								<span> / </span>
				  				<p
				  					onClick={() => setAssignToOtherModal(true)}
                    				className='btn-link btn'
                  				>
                    				Assign To Other
                  				</p>
							</div>
						</SectionMessage>
					</div>
					<div className="flex horizontally center-vertically mt2">
						<Input ref={VOUCHER} type="text" label="Confirmation Number" placeholder="Confirmation Number" className="mr1 flex-1"/>
					</div>
					{/* https://dice-antrepriz.atlassian.net/browse/CTOOO-211 */}
					<div className="flex horizontally center-vertically mt2">
						<Input ref={NAME} disabled={true} defaultValue={data.hotel.name} type="text" label="Hotel Name" placeholder="Hotel Name" className="mr1 flex-1"/>
					</div>
					<div className="flex horizontally center-vertically mt2">
						<Input ref={ADDRESS} disabled={true} defaultValue={data.hotel.address}  type="text" label="Hotel Address" placeholder="Hotel Address" className="mr1 flex-1"/>
					</div>
					<div className="flex horizontally center-vertically mt2">
						<Input defaultValue={data.hotel.city} disabled={true} ref={CITY} type="text" label="City" placeholder="Enter City" className="mr1 flex-1"/>
					</div>
					<div className="flex horizontally center-vertically mt2">
						<Input defaultValue={data.room.name} disabled={true} ref={ROOMNAME} type="text" label="Room Name" placeholder="Room Name" className="mr1 flex-1"/>
					</div> 
					<div className="flex horizontally center-vertically mt2">
						<Input defaultValue={data.room.mealType} disabled={true} ref={ROOMINCULSIONS} type="text" label="Room Inculsions" placeholder="Room Inculsions" className="mr1 flex-1"/>
					</div>
					<div className="flex horizontally center-vertically mt2">
						<Input defaultValue={data.room.cancellationPolicy} disabled={true}  ref={CANCELLATION} type="text" label="Cancellation Policy" placeholder="Entry Cancellation Policy" className="mr1 flex-1"/>
					</div>
					<div className="flex horizontally center-vertically mt2">
						<Input defaultValue={`${data.totalPrice}`} disabled={true}  ref={PURCHASEPRICE} type="text" label="Purchase Price Inclusive of GST" placeholder="Purchase Price" className="mr1 flex-1"/>
					</div>
					<div className="flex horizontally center-vertically mt2">
						<SelectInput disabled={true}  options={[{label:"CONTRACTED",value:"CONTRACTED"},{label:"NON-CONTRACTED",value:"NON-CONTRACTED"}]} ref={TYPE} type="number" label="Type" hidePlaceholder={true} className="mr1 flex-1"/>
					</div>
					<div className="flex horizontally center-vertically mt2">
						<Input ref={REMARKS} type="text" label="Remarks" placeholder="Internal remarks"  className="flex-1"/>
					</div>
					<div className="flex horizontally center-vertically mt4 ">
						<Button onClick={()=>{
							let purchasePrice = parseInt(PURCHASEPRICE.current.value, 10);
							let meal = ROOMINCULSIONS.current.value;
							let city = CITY.current.value;
							let cancellation = CANCELLATION.current.value;
							let roomName = ROOMNAME.current.value;
							let name = NAME.current.value;
							let address = ADDRESS.current.value;
							let voucher = VOUCHER.current.value;
							if(!voucher){
								TOAST.error("Enter a valid voucher")
								return
							}

							if(!name){
								TOAST.error("Enter a valid name")
								return
							}

							if(!address){
								TOAST.error("Enter a valid address")
								return
							}


							if(!city){
								TOAST.error("Enter a valid city")
								return
							}

							if(!roomName){
								TOAST.error("Enter a valid roomName")
								return
							}

							if(!cancellation){
								TOAST.error("Enter a valid cancellation")
								return
							}

							if(!meal){
								TOAST.error("Enter a valid meal")
								return
							}


							if(purchasePrice < 500){
								TOAST.error("Enter a valid purchase price")
								return
							}
							let remarks = REMARKS.current.value;
							if(!remarks){
								TOAST.error("Enter a valid remarks")
								return
							}
							let data = {
								hotel:{
									name,
									address,
									city
								},
								room : {
									name : roomName,
									meal : meal,
									cancellation:cancellation
								},
								amount: purchasePrice,
								purchasePrice,
								type:TYPE.current.value,
								voucher,
								bookingId:voucher,
								remarks
							}
							post(`/meta/contracted/${id}/confirm`,data, (e, r) => {
								if (r) {
									load(id)
									TOAST.success("Ticket confirmed successfully!")
									onClose()
								} else {
									Alert.handleError(e)
								}
							})
						}} className="btn-black btn-primary" margin="">
							Confirm Booking
						</Button>
						<Button onClick={()=>{
							setDecline(true)
						}} className="btn btn-danger" margin="ml2">
							Decline Booking
						</Button>
					</div>
				</div>
			}
			{
				decline && <Modal onClose={()=>{setDecline(false)}} title="Decline Remarks" description="Enter your decline remarks">

           {/* Add DropDown  https://dice-antrepriz.atlassian.net/browse/CTOOO-144 */}  

		   <SelectInput
            options={[
              { label: "Select Remarks", value: ""},
              { label: "Not Available", value: "Not Available" },
              { label: "Customer denied alternative", value: "Customer denied alternative" },
              { label: "TAT Breach", value: "TAT Breach" },
              { label: "Unable to reach the guest", value: "Unable to reach the guest" },
              { label: "Guest requested for cancellation", value: "Guest requested for cancellation" },
              { label: "Hotel not available, requested guest for self arraangement", value: "Hotel not available, requested guest for self arraangement" },
              { label: `Duplicate booking (${data.requestId})`, value: `Duplicate booking (${data?.requestId || "-"})` },
              { label: "Others", value:"Others" }
            ]}
            onChange = {(val)=>setDRemarks(val)}
            label='Remarks'
            hidePlaceholder={true}
            className='mr1 flex-1'
          />

          {
            DRemarks === "Others" && 
            <Input
              ref={OTHERREMARKS}
              type='text'
              label='Enter other remarks'
              placeholder='Enter your remarks'
              className='flex-1 mt2'
            />
          }



					<Button onClick={()=>{
						let remarks = DRemarks === 'Others' ? OTHERREMARKS.current.value : DRemarks;
						if (!remarks) {
						  TOAST.error("Enter a valid remarks");
						  return;
						}
						post(`/meta/contracted/${id}/decline`,{remarks}, (e, r) => {
							if (r) {
								load(id)
								TOAST.success("Ticket declined successfully!")
								setDecline(false)
								onClose()
							} else {
								Alert.handleError(e)
							}
						})
					}}>
						Cancel Booking
					</Button>
				</Modal>
			}
			{
				change && <Modal title="Change Hotel" onClose={()=>{setChange(false)}} description="Enter details to change hotel">
					<SearchInput renderResult={item=>{
						return (
							<div>
								<p style={{fontSize:14,fontWeight:600}}>
									{item.name},{item.city}
								</p>
								<p>
									{item.address}
								</p>
							</div>
						)
					}} resultKey="hotels" api="contracted/search" morph={false} placeholder="Hotel" label="Select Hotel"
								 onSelect={item => {
									 setHotel(item)
								 }} selected={undefined}/>
					<Input ref={ROOMNAME} type="text" label="Room Name" placeholder="Enter room name"/>
					<SelectInput options={[
						{
							value:"ROOMONLY",
							label:"Room Only"
						},
						{
							value:"BREAKFAST",
							label:"Break fast"
						},
						{
							value:"HALFBOARD",
							label:"Half Board"
						},
						{
							value:"FULLBOARD",
							label:"Full Board"
						}
					]} type="text" ref={MEALINFO} label="Meal Info"  morph={true}/>
					<SelectInput options={[
						{
							value:"NOTREFUNDABLE",
							label:"Not Refundable"
						},
						{
							value:"REFUNDABLEMINUSONE",
							label:"Refundable but one day penalty"
						},
						{
							value:"REFUNDABLEONEDAY",
							label:"Refundable if cancelled one day before"
						},
						{
							value:"FULLYREFUNDABLE",
							label:"Fully Refundable"
						}
					]} type="text" ref={CANCELLATION} label="Cancellation Policy"  morph={true}/>
					<p onClick={()=>{
						let name = ROOMNAME.current.value;
						let meal = MEALINFO.current.value;
						let cancellation = CANCELLATION.current.value;
						if(!name){
							TOAST.error("Enter a valid room name");
							return;
						}
						if(!meal){
							TOAST.error("Enter a valid room meal");
							return;
						}
						if(!cancellation){
							TOAST.error("Enter a valid room cancellation");
							return;
						}
						if(!hotel){
							TOAST.error("Select a valid hotel")
							return
						}
						let data = {
							name,meal,cancellation,hotel:hotel.id
						}
						post(`/meta/contracted/${id}/hotel.change`,data, (e, r) => {
							if (r) {
								load(id)
								setChange(false)
							} else {
								Alert.handleError(e)
							}
						})
					}} className="btn btn-black mt4">
						Change Hotel
					</p>
				</Modal>
			}

		{assignToOtherModal && (
		<Modal
			onClose={() => {
				setAssignToOtherModal(false);
				setSelectedAdmin(undefined);
			}}
			title="Assign To Other"
			description="Select an admin to assign"
			style={{
				height: 500
			}}
		>
			<SearchInput
				renderResult={item => {
					return (
						<p style={{ fontSize:14 }}>
							{item.name} ({item.email})
						</p>
					)
				}}
				resultKey="items"
				api="/admins"
				morph={false}
				label="Select Admin"
				onSelect={admin => {
					setSelectedAdmin(admin);
				}}
				placeholder="Select an admin"
			/>
			<Button
				onClick={() => {
					if (!selectedAdmin) {
						return TOAST.error('Select an admin to assign');
					}

					post(`/meta/contracted/${id}/${selectedAdmin.id}/assign`, {}, (e, r) => {
						if (r) {
							load(id);
							TOAST.success(`Ticket assigned to ${selectedAdmin.name}`);

							setSelectedAdmin(undefined);
							setAssignToOtherModal(false);
						} else {
							Alert.handleError(e);
						}
					});
				}}
				className='btn-black btn-primary'
			>
				Assign
			</Button>
		</Modal>
	  )}
		</SidePane>
	)
}
