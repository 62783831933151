import React, {useEffect, useRef, useState} from 'react'
import {get, post} from "../../../../Network/Axios";
import {useHistory, useParams} from "react-router-dom";
import {Button, Headings, Input, Loader, SidePane, Texts, TOAST} from "../../../../../retro";
import Bank from './bank.png'
import SidePaneModal from './Modal'
import useFunctionallityHook from "../../../../Hooks/useFunctionallityHook";
import useConfigHook from 'App/Hooks/useConfigHook';

export default function VirtualAccounts() {
	const {id} = useParams();
	const HISTORY = useHistory();
	const [Modal,SetModal] = useState(false)
	const [editModal,SetEditModal] = useState(false)
	const [loading,setLoading] = useState(true)
	const MULTI = useFunctionallityHook().multiDeposit
	const [accounts,setAccounts] = useState([])
	const ACCOUNT=useRef()
	const IFSCCODE=useRef()
	const CONFIG = useConfigHook();
	let ORIGIN = CONFIG.origin.code;
	const load = () => {
		get(`accounts/${id}`,(e,r)=>{
			if(r){
				setLoading(false)
				setAccounts(r.items)
			}
		});
	}
	useEffect(()=>{
		load()
	},[])
	if(loading){
		return  (
			<Loader/>
		)
	}

	const updateVA=()=>{
		let account=ACCOUNT?.current?.value
		let Ifsc=IFSCCODE?.current?.value
		const validateIfsc = (ifsc) =>{
			var pattern = /^[a-zA-Z0-9']+$/;
			return pattern.test(ifsc);
		}
		if(!account){
			TOAST.error("Please Enter Account Number")
			return
		}
		if(!Ifsc || !validateIfsc(Ifsc)){
			TOAST.error("Please Enter IFSC Code")
			return
		}
		let payload={
			ifsc:Ifsc,
			account:account,
			accountId:editModal?.id,
		}
		post(`accounts/account/${id}/update`,payload,(e,r)=>{
			if(r){
				TOAST.success("Virtual Account update Successfully")
				SetEditModal(undefined)
			}else{
				TOAST.error(JSON.stringify(e))
			}
		});
	}
	return (
		<>
			{
				MULTI && <Button margin="" className="btn-primary" onClick={()=>{
					SetModal(true)
				}}>
					+ Add Virtual Account
				</Button>
			}
			<div className="card-container mt4">
				{
					accounts.map(item=>{
						return (

							<div className="retro-card">
							<div onClick={()=>{
								HISTORY.push(`/app/accounts/${id}/${item.id}`)
							}} key={item.title} style={{
							}} >
								<div className='flex horizontally space-bw '>
									<Headings.Regular>
										{item.title}
									</Headings.Regular>
									{
										item?.defaultAccount && 
										<div className={`status-chip pending w-auto`}>Default</div>
									}
								</div>
								
								<div className="mb3 mt3">
									<Texts.Regular>
										{item.account}
									</Texts.Regular>
									<Texts.Light>
										{item.ifsc}
									</Texts.Light>
								</div>
								<Headings.Small>
									INR {item.balance}
								</Headings.Small>
							
							</div>

							{["YESUAT"].includes(ORIGIN)&&(
								<div className="flex flex-1 justify-end" >
								<i  onClick={()=>{
								SetEditModal(item)
								}} className="fa fa-edit"/>
								</div>
							)}
							
							</div>
						)
					})
				}
			</div>
			{
				!accounts.length &&
				<div style={{
					height:600
				}} className="center w-100 flex vertically">
					<img alt="bank" src={Bank} style={{
						height:250,
						width :250
					}}/>
					<Headings.Large>
						No virtual accounts
					</Headings.Large>
					<Texts.Regular>
						no virtual account found in repository
					</Texts.Regular>
				</div>
			}
			{
				Modal && <SidePaneModal onClose={()=>{SetModal(false)}} onSubmit={(data)=>{
					post(`accounts/${id}`,data,(e,r)=>{
						if(r){
							load()
							TOAST.success("Account addition successfull")
						}else{
							TOAST.handleError(e)
						}
					});
					SetModal(false)
				}}/>
			}


{
				editModal &&   <SidePane
				width={700}
                onClose={() => {
					SetEditModal(undefined);
                }}
                description="Enter details below to update virtual Account"
                title="Update Virtual Account"
              >
                <Input
                ref={ACCOUNT} type={"number"} placeholder="Enter Account Number" label="Account Number *" />
                <Input ref={IFSCCODE}
                placeholder="Enter IFSC Code" label="IFSC Code *" />
                <Button onClick={updateVA} className="btn-primary mt5">
                  Update Virtual Account
                </Button>
              </SidePane>
			}
		</>
	)
}