import React, {useRef, useState} from 'react'
import {Button, ProgressIndicator, Pages, Form, Input, TOAST, Navigation, Modal} from "../../../../../../retro";
import {post} from "../../../../../Network/Axios";
import useConfigHook from 'App/Hooks/useConfigHook';
import CheckBox from 'retro/Inputs/CheckBox';
export default function CreateClient({data,onBack,onReset}) {
    const [selectedModules, setSelectedModules] = useState([]);
    const handleModuleSelection = (module, isChecked) => {
        setSelectedModules(prevState => {
            if (isChecked) {
                return [...prevState, module];
            } else {
                return prevState.filter(item => item !== module);
            }
        });
    };

    const ACCOUNT = useRef();
    const IFSC = useRef();
    const PARTNER = useRef();
    const TSP = useRef();
    const CONFIG = useConfigHook();
    return (
        <>
            <ProgressIndicator active={2} stages={2}/>
           <div className="flex  mt4 horizontally center-vertically">
               <div onClick={()=>{
                    // saving the state on back
                    let account = ACCOUNT.current.value;
                    let ifsc = IFSC.current.value;
                    let partner = PARTNER.current.value;
                    let tsp = TSP.current.value;
                   onBack({account, ifsc, partner, tsp});
               }} style={{
                   width:40,
                   height:40,
                   cursor:'pointer',
                   borderRadius:20,
                   border:'1px solid #dedede',
                   marginRight:20
               }} className='flex center'>
                   <i style={{fontSize:22}} className="fas fa-arrow-left"/>
               </div>
               <Pages.Title title="Account Details" description="Enter account details to on-board this client"/>
           </div>
            <Form focus={true} className="mt4" containerStyle={{width:'100%',maxWidth:600}}>
                <Input defaultValue={data.account||""} ref={ACCOUNT} type="number" label="Account Number" placeholder="Enter Account Number" morph={true}/>
                <Input defaultValue={data.ifsc||""} ref={IFSC} type="text" label="Ifsc Code" placeholder="Enter Ifsc Code" morph={true}/>
                <Input defaultValue={data.partner||""} ref={PARTNER} type="text" label="Payout/Partner Key" placeholder="Enter Payout/Partner Key" morph={true}/>
                <Input defaultValue={data.tsp||""} ref={TSP} type="text" label="TSP Key" placeholder="Enter TSP Key" morph={true}/>
                <div style={{
                    marginTop : '6px'
                }}>
               <h4>Select Modules</h4>
                {CONFIG?.module?.map((module, index) => (
                            <CheckBox
                                key={index}
                                label={module}
                                
                                onChange={(isChecked) => handleModuleSelection(module, isChecked)}
                            />
                        ))}
                </div>
               
                 
                <Button onClick={()=>{



                    let account = ACCOUNT.current.value;
                    let ifsc = IFSC.current.value;
                    let partner = PARTNER.current.value;
                    let tsp = TSP.current.value;

                    const validateIfsc = (ifsc) =>{
                        var pattern = /^[a-zA-Z0-9']+$/;
                        return pattern.test(ifsc);
                    }
                    
                    if(!account){
                        TOAST.error("Enter a valid account number")
                        ACCOUNT.current.style.border = '2px solid red';
                        return
                    }
                    if(!ifsc || !validateIfsc(ifsc)){
                        TOAST.error("Enter a valid ifsc code")
                        IFSC.current.style.border = '2px solid red';
                        return
                    }
                    if(!partner || !validateIfsc(partner)){
                        TOAST.error("Enter a valid partner key")
                        PARTNER.current.style.border = '2px solid red';
                        return
                    }
                    if(!tsp || !validateIfsc(tsp)){
                        TOAST.error("Enter a valid tsp key")
                        TSP.current.style.border = '2px solid red';
                        return
                    }
                    let onboard = {
                        ...data,account,ifsc,partner,tsp,moduleName: selectedModules
                    }

                
                    
                    post('/clients/neo',onboard,(e,r)=>{
                        if(r){
                            onReset()
                            TOAST.success("Client on-boarded successfully!!")
                        }else{
                            TOAST.handleError(e)
                        }
                    })

                }} margin="mt4">
                    On-Board Client
                </Button>
            </Form>
        </>
    )
}
