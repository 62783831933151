import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  ChipsSelect,
  Form,
  Input,
  NavDivider,
  Navigation,
  Seperator,
  Switch,
  TOAST,
  Tags
} from 'retro';
import { get, post } from 'App/Network/Axios';
import { useHistory } from 'react-router-dom';
import ROLES, { YESBNK_ROLES , CT_ROLES} from 'App/Constants/Roles';
import RESTRICT from 'App/Constants/RestrictViews';
import SelectClient from './SelectClient';
import useConfigHook from 'App/Hooks/useConfigHook';

export default function index() {
  const CONFIG = useConfigHook();
  const [roles, setRoles] = useState([]);
  const [isSuper,setIsSuper] = useState(false);
  const [restrict, setRestrict] = useState([]);
  const [addClient,setAddClient] = useState(false);   // state to open modal to add client 
  const [selectedClient,setSelectedClient] = useState([]);
  const [allClients,setAllClients] = useState([]);
  const NAME = useRef();
  const EMAIL = useRef();
  const MOBILE = useRef();
  const HISTORY = useHistory();
  const EMPLOYEEID = useRef();

  // set the user roles to be displayed
  const userRoles = CONFIG.origin.code === 'YESUAT' || CONFIG.origin.code==='YES-PROD' ? YESBNK_ROLES : ROLES;
  const ctRoles = (CONFIG.origin.code === 'CLRTRP' || CONFIG.origin.code === 'CLT-STG')? CT_ROLES : undefined;  // object having values as array of roles
  const [CtRolesModal, setCtRolesModal] = useState({
    FINANCE:false,
    SALES:false,
    OPERATIONS:false,
  })

  function validateEmail(email) {
    var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
    }
    function validateEmpCode(code) {
      var pattern = /^[a-zA-Z0-9']+$/;
      return pattern.test(code);
  }
  useEffect(()=>{
    get(`/clients`,(e,r)=>{
      if(r) {
          setAllClients(r.items)
      }
  })
  },[])

  let fetchArrayofName = (all,selected) => {  /* To get id of selected client */
    let items = [];
    all.forEach(item=>{
        if(_.includes(selected,item.name)) {
            items.push(item.id)
        }
    })

    return items;
}

  const addUser = () => {
    let name = NAME.current.value;
    let email = EMAIL.current.value;
    let mobile = MOBILE.current.value;
    let code = EMPLOYEEID.current.value;
    if (!name || name.length < 2 || name[0]===" ") {
      TOAST.error('Kindly enter a valid name');
      NAME.current.style.border = '2px solid red';
      return;
    }
    if (!email || !validateEmail(email)) {
      TOAST.error('Kindly enter a valid email');
      EMAIL.current.style.border = '2px solid red';
      return;
    }
    if (!mobile || mobile.length !== 10) {
      TOAST.error('Kindly enter a valid mobile');
      MOBILE.current.style.border = '2px solid red';
      return;
    }
    if (parseInt(mobile) < 5000000000) {
      TOAST.error('Kindly enter a valid mobile');
      MOBILE.current.style.border = '2px solid red';
      return;
    }
    if (!code || code.length < 2 || !validateEmpCode(code)) {
      TOAST.error('Kindly enter a valid code');
      EMPLOYEEID.current.style.border = '2px solid red';
      return;
    }
    if (roles.length < 1) {
      TOAST.error('Kindly select atleast one role.');
      return;
    }
    let data = {
      name,
      email,
      mobile,
      code,
      roles,
      restrictViews:restrict,
      clientIds:fetchArrayofName(allClients,selectedClient)
    };
    console.log(data);
    post(`/admins`, data, (e, r) => {
      if (r) {
        HISTORY.push('/app/admins');
        TOAST.success('User created successfully');
      } else {
        TOAST.handleError(e);
      }
    });
  };

  const onMobileKeyDown = (e)=>{
    if(e.key === 'ArrowRight' || e.key === 'ArrowLeft' || e.key === 'Backspace') {
      return;
    }
    if (MOBILE.current.value.length === 10 || !/^[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  }

  // toggle function to select or deselect super
  const toggleSuper = ()=>{
    if(isSuper){
      setIsSuper(false);
      setRoles([]);
      setCtRolesModal({
        FINANCE:false,
        SALES:false,
        OPERATIONS:false,
      })
    }
    else{
      setIsSuper(true);
      setRoles([...Object.keys(userRoles),"SUPER"]);
    }
  }
  const updateRoles = () => {
    if(roles.includes('CLIENTS_WRITE') && !roles.includes('CLIENTS')) {
      const updatedRoles = [...roles, 'CLIENTS'];
      setRoles(updatedRoles);
      return roles;
    }
    return roles;
  };
  const handleSelected = () =>{
    if(window.origin.includes("yesleap") || window.origin.includes('yespayleaf')){
      return updateRoles()
    }else{
      return roles}}
  // set super true if all the roles are selected
  useEffect(()=>{
    if(roles.length === Object.keys(userRoles).length){
      setIsSuper(true);
      setRoles([...Object.keys(userRoles),"SUPER"]);
    }
  },[roles])

  return (
    <>
      <Navigation
        backUrl={''}
        title='Add Admins'
        chips={['Admins', 'Add Admin']}
        description='Enter details below to add a new admin.'
      />
      <NavDivider />
      <Form className='mt4'>
        <Input
          onKeyDown={(e) => {
            if (!/^[a-zA-Z ]*$/.test(e.key)) {
              e.preventDefault();
            }
          }}
          ref={NAME}
          className='mb2'
          type='text'
          placeholder='Enter name'
          label='Name *'
          morph={false}
        />
        <Input
          ref={EMAIL}
          className='mb2'
          type='text'
          placeholder='Enter email address'
          label='Email Address *'
          morph={false}
        />
        <Input
          onKeyDown={(e) => onMobileKeyDown(e)}
          ref={MOBILE}
          className='mb2'
          type='text'
          placeholder='Enter mobile number'
          label='Mobile Number *'
          morph={false}
        />
        <Input
          onKeyDown={(e) => {
            if (!/[0-9a-zA-Z]/.test(e.key)) {
              e.preventDefault();
            }
          }}
          ref={EMPLOYEEID}
          className='mb2'
          type='text'
          placeholder='Enter employee id'
          label='Employee Id *'
          morph={false}
        />
        <Seperator />
        <div className="flex align-center justify-between">
          <label className="uppercase">
            Is Super User
          </label>
        <Switch state={isSuper} label='Super User' onChange={toggleSuper}/>
        </div>
        {/* only show if not super */}

        <Seperator />
        {
          !isSuper && (CONFIG.origin.code === 'CLRTRP' || CONFIG.origin.code === 'CLT-STG') ?
          <div className="flex horizontal">
            <div className={CtRolesModal['FINANCE']?'chip-active':'chip'} onClick={()=>{
            if (CtRolesModal['FINANCE']){
              setRoles([]);
              setCtRolesModal({
                FINANCE:false,
                SALES:false,
                OPERATIONS:false,
              })
              }
              else{
                setRoles([...ctRoles['FINANCE']])
                setCtRolesModal({
                  FINANCE:true,
                  SALES:false,
                  OPERATIONS:false,
                })}}}>FINANCE
            </div>

            <div className={CtRolesModal['SALES']?'chip-active':'chip'} onClick={()=>{
            if (CtRolesModal['SALES']){
              setRoles([]);
              setCtRolesModal({
                FINANCE:false,
                SALES:false,
                OPERATIONS:false,
              })
              }
              else{
                setRoles([...ctRoles['SALES']])
                setCtRolesModal({
                  FINANCE:false,
                  SALES:true,
                  OPERATIONS:false,
                })}}}>SALES
            </div>

            <div className={CtRolesModal['OPERATIONS']?'chip-active':'chip'} onClick={()=>{
            if (CtRolesModal['OPERATIONS']){
              setRoles([]);
              setCtRolesModal({
                FINANCE:false,
                SALES:false,
                OPERATIONS:false,
              })
              }
              else{
                setRoles([...ctRoles['OPERATIONS']])
                setCtRolesModal({
                  FINANCE:false,
                  SALES:false,
                  OPERATIONS:true,
                })}}}>OPERATIONS
            </div>
          </div> : undefined
        }

        {!isSuper ?
              <ChipsSelect
                options={Object.values(userRoles)}
                label='Select Roles *'
                type='multiple'
                selected={handleSelected()}
                onSelect={setRoles}
              />
            : undefined
        }
        <Seperator />
        {
          CONFIG.origin.code !== 'YESUAT' && CONFIG.origin.code !=='YES-PROD'  &&
          <>
          <ChipsSelect
            options={Object.values(RESTRICT)}
            label='Restrict Views'
            type='multiple'
            selected={restrict}
            onSelect={setRestrict}
            />
          <Seperator />
          </>
        }
        <Tags 
          tags={selectedClient} 
          setTags={setSelectedClient} 
          />
       {CONFIG.origin.code !== 'YESUAT' && CONFIG.origin.code!=='YES-PROD' && <><Button onClick={()=>{setAddClient(true)}} className='btn btn-black btn-sm'>
               Add Client (optional)
           </Button><Seperator/></>} 

        <Button onClick={addUser} className='btn-primary mt5'>
          + Add Admin
        </Button>
      </Form>

       {addClient? 
          <SelectClient 
            allClients={allClients} 
            onNext={(items)=>{
              setSelectedClient(items);
              setAddClient(false);
            }}
            selectedClients={selectedClient}
            onClose={()=>setAddClient(false)} 
            /> : 
          null}
    </>
  );
}
