import React from 'react'
import {Route, Switch} from "react-router-dom";
import {NotFound} from "../../../../../retro";
import Routes from "./routes";
import UpdateBreakupController from './UpdateBreakupController';
import AddHotelGstController from './AddHotelGstController';
import ShiftClientGstinController from './ShiftClientGstinController';
import ShiftHotelEntityController from './ShiftHotelEntityController';
import RegenerateZeroChargeInvoiceController from './RegenerateZeroChargeInvoiceController';
import MoveToAgency from './MoveToAgency';
import CancellationCharges from './CancellationCharges'
import ShiftFlightEntityController from './ShiftFlightEntityController'
import CancelAgency from './CancelReseller';
import CplToCtpl from './CplToCtplLtd'
import ShiftFlightAgencyEntityController from './ShiftFlightAgencyEntityController';
import UpdateCityAndMoveToReseller from './AgencyToSeller';
import MoveToDifferentCity from './MoveToDifferentCity';
import VisaAgencyController from './VisaAgency/VisaAgency';
import TrainAgencyController from './TrainAgency/TrainAgency';
import BusAgencyController from './BusAgency/BusAgency';
import UpdatHSNCode from "./UpdateHSNCode"

export default function AllBooking() {
	return (
		<Switch>
			<Route path="/app/apps/meta/utilities" exact>
				<Routes/>
			</Route>
			<Route exact path="/app/apps/meta/utilities/flight/breakup">
				<UpdateBreakupController/>
			</Route>
			<Route path="/app/apps/meta/utilities/hotel/agency/gst" exact>
				<AddHotelGstController/>
			</Route>
			<Route path="/app/apps/meta/utilities/hotel/agency/gst/update" exact>
				<ShiftClientGstinController/>
			</Route>
			<Route path="/app/apps/meta/utilities/hotel/reseller/entity" exact>
				<ShiftHotelEntityController/>
			</Route>
			<Route path="/app/apps/meta/utilities/flight/reseller/fixGst" exact>
				<RegenerateZeroChargeInvoiceController/>
			</Route>
			<Route path="/app/apps/meta/utilities/flight/reseller/entity" exact>
				<ShiftFlightEntityController/>
			</Route>
			<Route path="/app/apps/meta/utilities/hotel/reseller/agency" exact>
				<MoveToAgency/>
			</Route>
			<Route path="/app/apps/meta/utilities/hotel/reseller/cancel" exact>
				<CancelAgency/>
			</Route>
			<Route exact path="/app/apps/meta/utilities/flight/reseller/cancelInvoice">
				<CancellationCharges/>
			</Route>
			<Route exact path="/app/apps/meta/utilities/cplToCtpl">
				<CplToCtpl/>
			</Route>
			<Route exact path="/app/apps/meta/utilities/flight/agencySwitch">
				<ShiftFlightAgencyEntityController/>
			</Route>
			<Route exact path="/app/apps/meta/utilities/updatecityandmovetoreseller">
				<UpdateCityAndMoveToReseller/>
			</Route>
			{/* https://dice-antrepriz.atlassian.net/browse/CTOOO-142 */}
			<Route exact path="/app/apps/meta/utilities/updatehsncode">
				<UpdatHSNCode/>
			</Route>
			<Route exact path="/app/apps/meta/utilities/movetodifferentcity">
				<MoveToDifferentCity/>
			</Route>
			<Route exact path="/app/apps/meta/utilities/visaagency">
				<VisaAgencyController />
			</Route>
			<Route exact path="/app/apps/meta/utilities/trainagency">
				<TrainAgencyController />
			</Route>
			<Route exact path="/app/apps/meta/utilities/busagency">
				<BusAgencyController />
			</Route>
			<NotFound/>
		</Switch>
	)
}
