import Moment from "moment";

function ReasonForTravel(item){
	return item.trip
		? item.trip.attrs['Reason For Travel'] || '-'
		: item.allFields
			? item.allFields['Reason For Travel'] || '-'
			: '-'
}

/**
 * 'Traveller Name',
 * 		'Traveller Id',
 * 		'Traveller Cost Center',
 * 		'Traveller Department',
 * 		'Traveller Grade',
 * 		'Traveller Location Code',
 * 		'Traveller Entity',
 * @param item
 * @returns {*[]}
 * @constructor
 */
function Owners(item){
	let values = []
	if (item.trip && item.trip.owner) {
		values.push(
			item.owner.ownerType === 'TDESK' ? 'IMPLANT' : item.owner.ownerType
		);
		item.owner = item.trip.owner;
		if(item && item.ticket && item?.ticket?.travellers){
			values.push(item?.ticket?.travellers[0]?.name || item.owner.ownerName || '-');
		}else{
			values.push(item.owner.ownerName || '-');
		}
		values.push(item.owner?.fields?.employeeId || item.owner?.ownerId || '-');
		values.push( item?.owners?.[0]?.fields?.['costCenter'] || item?.trip?.attrs?.costCenter || '-' )
		values.push( item?.owners?.[0]?.fields?.['department'] || '-' );
		values.push( item?.owners?.[0]?.fields?.['team'] || '-' );
		values.push( item?.owners?.[0]?.fields?.['location'] || '-' );
		values.push(
			item.owner.ownerType === 'EMPLOYEE'
				? item.owner.fields['entity'] || '-'
				: item.officeName || '-'
		);
	} else if (item.owner) {
		values.push(
			item.owner.ownerType === 'TDESK' ? 'IMPLANT' : item.owner.ownerType
		);
		values.push(item?.ticket?.travellers && item?.ticket?.travellers.length?item?.ticket?.travellers[0]?.name : item.owner.ownerName || '-');
		values.push(item.owner?.fields?.employeeId || item.owner?.ownerId || '-');
		values.push( item?.owners?.[0]?.fields?.['costCenter'] || item?.trip?.attrs?.costCenter || '-' );
		values.push( item?.owners?.[0]?.fields?.['department'] || '-' );
		values.push( item?.owners?.[0]?.fields?.['team'] || '-' );
		values.push( item?.owners?.[0]?.fields?.['location'] || '-' );
		values.push(
			item.owner.ownerType === 'EMPLOYEE'
				? item.owner.fields['entity'] || '-'
				: item.officeName || '-'
		);
	} else {
		values.push('-');
		values.push('-');
		values.push('-');
		values.push('-');
		values.push('-');
		values.push('-');
		values.push('-');
		values.push('-');
	}
	return values;
}

function ReasonForRefund(item){
	if (item.allFields) {
		return item?.allFields['refund.remarks'] || '-'
	} else {
		return "-"
	}
}


function Approvals(item) {
	if (item.trip) {
		return [
			item.trip ? item.trip.attrs['approvedOne.name'] || '-' : '-',
			item.trip ? item.trip.attrs['approvedOne.code'] || '-' : '-',
			item.trip ? item.trip.attrs['approvedOne.costCenter'] || '-' : '-',
			item.trip ? item.trip.attrs['approvedOne.team'] || '-' : '-',
			item.trip ? item.trip.attrs['approvedOne.entity'] || '-' : '-',
			item.trip ? item.trip.attrs['approvedOne.status'] || '-' : '-',
			item.trip ? item.trip.attrs['approvedOne.sentOn'] || '-' : '-',
			item.trip ? item.trip.attrs['approvedOne.updatedOn'] || '-' : '-',
			item.trip ? item.trip.attrs['approvedTwo.name'] || '-' : '-',
			item.trip ? item.trip.attrs['approvedTwo.code'] || '-' : '-',
			item.trip ? item.trip.attrs['approvedTwo.costCenter'] || '-' : '-',
			item.trip ? item.trip.attrs['approvedTwo.team'] || '-' : '-',
			item.trip ? item.trip.attrs['approvedTwo.entity'] || '-' : '-',
			item.trip ? item.trip.attrs['approvedTwo.status'] || '-' : '-',
			item.trip ? item.trip.attrs['approvedTwo.sentOn'] || '-' : '-',
			item.trip ? item.trip.attrs['approvedTwo.updatedOn'] || '-' : '-',
		];
	} else if (item.allFields) {
		return [
			item.allFields['approvedOne.name'] || '-',
			item.allFields['approvedOne.code'] || '-',
			item.allFields['approvedOne.costCenter'] || '-',
			item.allFields['approvedOne.team'] || '-',
			item.allFields['approvedOne.entity'] || '-',
			item.allFields['approvedOne.status'] || '-',
			item.allFields['approvedOne.sentOn'] || '-',
			item.allFields['approvedOne.updatedOn'] || '-',
			item.allFields['approvedTwo.name'] || '-',
			item.allFields['approvedTwo.code'] || '-',
			item.allFields['approvedTwo.costCenter'] || '-',
			item.allFields['approvedTwo.team'] || '-',
			item.allFields['approvedTwo.entity'] || '-',
			item.allFields['approvedTwo.status'] || '-',
			item.allFields['approvedTwo.sentOn'] || '-',
			item.allFields['approvedTwo.updatedOn'] || '-',
		];
	} else {
		return [
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
			'-',
		];
	}
}


function BookingType(item) {
	if (!item.service) {
		return 'Not Defined';
	}
	item.service = item.service.toUpperCase();
	if (item.service.includes('FLIGHT_AMENDMENT')) {
		return "Flight Offline Amendment"
	}
	else if (item.service.includes('FLIGHT')) {
		return 'Flight';
	} else if (item.service.includes('HOTEL')) {
		return 'Hotel';
	} else if (item.service.includes('BUS')) {
		return 'Bus';
	} else if (item.service.includes('TRAIN')) {
		return 'Train';
	}else if (item.service.includes('VISA')) {
		return 'Visa';
	}else if (item.service.includes('INSURANCE')) {
		return 'Insurance';
	}
	return item.service;
}
export default function Common(item,dates){
	let values =  [

	]
	//Statement Period
	values.push(
		`${Moment(dates.startDate).format('DD MMM YYYY')} - ${Moment(
			dates.endDate
		).format('DD MMM YYYY')}`,
	)
	// Advance Booking Days
	if (item.date) {
		values.push(
			`${parseInt(
				Moment(item.date).diff(Moment(item.item.createdAt)) / 86400000,
				10
			)} days`
		);
	} else {
		values.push('-');
	}
	values.push(item?.clientName||"-");
	values.push(item?.clientId||"-");
	// Default to seller gst name otherwise cleartrip packages
	values.push(item.item?.config?.sellerGst?.name || 'Cleartrip Packages and Tours Private Limited');
	// Ledger Id
	values.push(item.item.id);
	// Account Da Id
	values.push(item.item.accountId);
	// Da Number
	values.push(item.item.accountNumber);
	// Credit / Debit Type
	values.push(item.item.financeType);
	// Booking Type
	values.push(item.item.type === 'DASHBOARD' ?"DEPOSIT ACCOUNT/MANUAL":  item.item.type === 'BOOKING' ? 'BOOKING' : 'REFUND');

	if(item.item.financeType === 'CREDIT'){
		// Amount of Ledger
		values.push(-1 * item.item.amount);
	}else{
		// Amount of Ledger
		values.push(item.item.amount);
	}
	// Is this booking Refunded
	values.push(item?.refundAmount || "-");
	// Is this booking settled at Meta Apis Level
	values.push(item.item.settled ? 'yes' : 'no');
	// Start Balance
	values.push(
		parseFloat(`${
			item.item.financeType === 'CREDIT'
				? item.item.balance - item.item.amount
				: item.item.balance + item.item.amount
		}`)
	);
	// End Balance
	values.push(item.item.balance);
	// Creation Date
	values.push(Moment(item.item.createdAt).format('DD MMM YYYY'));
	// Creation Time
	values.push(Moment(item.item.createdAt).format('HH:mm a'));
	// Booking Type
	values.push(BookingType(item))
	// Office Name || Entity Name
	values.push(item.officeName || '-');
	// Department Name
	values.push(item.departmentName || '-');
	// Trip Title
	values.push(item.trip?.title || '-');
	// Trip Id
	values.push(item.trip?.tripId || '-');
	// Trip Status
	values.push(item.trip?.status || '-');
	// Trans Id
	if (item.transId) {
		let transId = item.transId;
		if (!transId.includes('RI'))
			transId = 'RI' + transId;
		values.push(transId);
	} else {
		values.push(item.item.referenceId || '-');
	}
	// Reason For Travel
	values.push(ReasonForTravel(item))
	// Booking Status
	if (item.status) {
		if (item.status === 'COMPLETED.INVOICE') {
			values.push('COMPLETED');
		} else if (item.status === 'REFUNDED.INVOICE') {
			values.push('REFUNDED');
		} else {
			values.push(item.status);
		}
	} else {
		values.push('-');
	}
	// Reason For Refund
	values.push(ReasonForRefund(item))
	// Booked By
	values.push(item.allFields ? item.allFields['emulatedBy'] || '-' : '-');
	// Booked By Code
	values.push(item.allFields ? item.allFields['emulatedCode'] || '-' : '-');
	// Who did this booking
	values.push(...Owners(item))  
	// Who approved this booking
	values.push(...Approvals(item))
	values.push(isInternational(item) ? 'International' : 'Domestic');
	// Booking Type
	values.push(BookingType(item))
	return values;
}


function OccupancyDetails(item) {
	try {
		let travellers = [];
		if (item.service === 'HOTEL') {
			travellers = item.ticket?.travellers.map((owner) => owner.name);
			return `${travellers.length} Occupancy`;
		} else {
			return '-';
		}
	}catch (e){
		return "-"
	}
}

const referenceId = item => {
	if(item.owners){
		let referenceId = undefined;
		item.owners.forEach(x=>{
			if(x.ownerType==="GUEST"){
				if(x.fields.referenceId){
					referenceId = x.fields.referenceId;
				}
			}
		})
		return referenceId || "-"
	}
	return "-"
}



const Common2 = (item,value) => {

	
	const tag = item?.item?.config?.tags;
	let AirlineCancellationFees=item?.item?.config?.refundInfos.reduce((acc,cur)=>acc+cur?.airlineCharges,0)
	//https://dice-antrepriz.atlassian.net/browse/CTOOO-279
	const tagData = [tag?.["Booker Name"] || '-',tag?.["Business Area"] || '-', tag?.["Company Code"] || '-',item?.["costCenterId"]|| tag?.["Cost Center"] || '-',
	tag?.["Employee Id"] || '-', tag?.["Employee Name"] || '-',tag?.["TR ID"]||tag?.["Trip request ID"]  || '-',tag?.["Vendor Code"] || '-',item?.emulatedBy||"-",item?.emulatedByCode||"-"
	
]


	if(item.ticket && item.ticket.travellers && item.ticket.travellers.length){
		return [
			item.ticket?.travellers[0]?.email || '-',
			item.ticket?.fareType || '-',
			OccupancyDetails(item),
			value,
			referenceId(item),
			item?.item?.config?.file || '-',
			...tagData
		]
	}
	return [
		'-',
		item.ticket?.fareType || '-',
		OccupancyDetails(item),
		value,
		referenceId(item),
		item?.item?.config?.file || '-',
		...tagData
	]
}

export {Common2}


export const CL_GSTS = {
	"37AAHCC1775A1ZW": "Ground Floor, Bearing No 18/795-F33, Sy No 459, 9th Cross, 60 Feet Road, Guntakal Market Sub Post Office, Tilak Nagar, Guntakal, Ananthapuramu, Andhra Pradesh, 515801",
	"12AAHCC1775A1Z8": "G Sector, ., Itanagar, Papum Pare, Arunachal Pradesh, 791113",
	"18AAHCC1775A1ZW": "LR Dag no 1030, Ward No 18, Adityam, Mouza Ulubari, Dr BK Kakoti Road, Police Headquarters Assam, Ulubari, Guwahati, Kamrup Metropolitan, Assam, 781007",
	"10AAHCC1775A1ZC": "M/s Pristine Magadh Infrastructure Pvt Ltd Siding, Near Bihta Railway Station, Old Sugar Mill Campus, Unnamed Road, Maner Sweets, Bihta Mushari, Bihta Patna, Patna, Bihar, 801103",
	"04AAHCC1775A1Z5": "2ND FLOOR, 26/9, Dakshan Marg, Distilleries Pvt Ltd, Business & Industrial Park 1, Chandigarh, Chandigarh, Chandigarh, 160002",
	"22AAHCC1775A1Z7": "Ground floor, L K Corporate and Logistics, Block E, Dumartarai, Gali Number 1, Anil Medical and General Store, Shatabdi Nagar, Raipur, Raipur, Chhattisgarh, 492001",
	"26AAHCC1775A1ZZ": "Second, A-209, Govinda Commercial Complex, Umaikui Road, Kilvani Naka, Dadra And Nagar Haveli, Dadra and Nagar Haveli and Daman and Diu, 396230",
	"25AAHCC1775A1Z1": "Second, A-201, Karan Complex, Near Jog Hospital, Nani Daman, Daman, Daman and Diu, 396210",
	"26AAHCC1775A2ZY": "Second, A-201, Karan Complex, Near Jog Hospital, Nani Daman, Daman, Dadra and Nagar Haveli and Daman and Diu, 396210",
	"07AAHCC1775A1ZZ": "1974, Shaheed Balbir Singh Marg, Alipur Sub Post Office, Alipur, New Delhi, North Delhi, Delhi, 110036",
	"30AAHCC1775A1ZA": "1 2 3 4 Subh ladh Arcade, Vodlemmol kakoda, Kakoda Industrial Estate, South Goa, Goa, 403706",
	"24AAHCC1775A1Z3": "10B, Haripark Society, Pavan Party Ankur, Naranpura, Ahmedabad, Gujarat, 380013",
	"06AAHCC1775A1Z1": "5th Floor, ABL Workspaces, Plot No.44, Sector 44,Gurugram, Gurugram, Haryana, 122003",
	"02AAHCC1775A1Z9": "VPO Ghurkari, Unnamed Road, Country Garden Home Stay, Kangra, Kangra, Himachal Pradesh, 176001",
	"01AAHCC1775A1ZB": "Chaitanya Complex, Prem Bhawan, Residency Road, Jammu, Jammu, Jammu and Kashmir, 180001",
	"20AAHCC1775A1ZB": "2ND FLOOR, Khata no 36, New Plot no 1601 and 1602, Grand Trunk Road, Chemist and Druggist, Govindpur More, Gobindpur, Dhanbad, Jharkhand, 828109",
	"29AAHCC1775A1ZT": "TOWER A, Embassy Tech Village, Outer Ring Road, Devarabeesanahalli Village, Bengaluru Urban, Karnataka, 560103",
	"32AAHCC1775A1Z6": "No 37/3055, J&Co Chambers, Manimala Road, Edappally, Kochi, Ernakulam, Kerala, 682024",
	"23AAHCC1775A1Z5": "2, 1669, Sahkari Hospital Road, Gandhi Nagar Sub Post Office, Gandhi Nagar, Bhopal, Bhopal, Madhya Pradesh, 462036",
	"27AAHCC1775A1ZX": "10th Floor, R City Mall, Sahakar Bhavan, Lal Bahadur Shastri Marg, Sahakar Bhavan Sub Post Office, Ghatkopar West, Mumbai, Mumbai Suburban, Maharashtra, 400086",
	"17AAHCC1775A1ZY": "3rd floor, Ri Bhoi District Court, Nongpoh Main Road, Ri Bhoi Presbyterian Synod Office, Nongpoh, Ri Bhoi, Meghalaya, 793102",
	"13AAHCC1775A1Z6": "3rd Floor, T.F-19, Millennium Market, Opp. Durga Mandir, Old Daily Market, Dimapur, Nagaland, 797112",
	"21AAHCC1775A1Z9": "Ground Floor, Mankeswari Complex, Bhawanipatna Main Road, Post Office Passport Seva Kendra, Bhawanipatna, Kalahandi, Odisha, 766001",
	"34AAHCC1775A1Z2": "Ground Floor, 380, Jeyasakthi Illam, 2nd Main Road, Lawspet Post Office, Lawspet, Puducherry, Puducherry, Puducherry, 605008",
	"03AAHCC1775A1Z7": "Nahar Industrial Enterprises Ltd, Post Office Road, Focal Point Sub Post Office, Focal Point, Ludhiana, Ludhiana, Punjab, 141010",
	"08AAHCC1775A1ZX": "Ground Floor, Plot No 1/33, Kanakpura Industrial Area, Sirsi Road, Jhotwara Sub Post Office, Jhotwara, Jaipur, Jaipur, Rajasthan, 302012",
	"33AAHCC1775A1Z4": "Level 2, Indospace skll Logistics Park, Nataraja Road, Arulmigu Sri Selvashakthi Vinayagar Temple, Sulur, Coimbatore, Tamil Nadu, 641402",
	"36AAHCC1775A1ZY": "CFCA, Plot no 6,7, Admin Building, Mumbai Highway, Patancheru Ie Sub Post Office, Patancheru, Hyderabad, Sangareddy, Telangana, 502319",
	"16AAHCC1775A1Z0": "Sudip Majumdar, Near Bajaj showroom, Belonia Main Road, Beloniya Sub Post Office, Belonia, Belonia, South Tripura, Tripura, 799155",
	"09AAHCC1775A1ZV": "House No.40, Radha Kunj, Gali No. 2, Nand Gram, Ghaziabad, Uttar Pradesh, 201003",
	"05AAHCC1775A1Z3": "Shop No 251 Niranjanpur, GMS Road, Rao Electricals, Mehu Wala Mafi, Dehradun, Dehradun, Uttarakhand, 248171",
	"19AAHCC1775A1ZU": "9th Floor, Major Arterial Road, DLF IT Park-1, Tower-A, Street Number 174, Ganga Nursery, Jatragachhi, New Town, North Twenty Four Parganas, West Bengal, 700156"
};


// checks the type of service to determine the method
export const isInternational = (booking)=>{
	try {
		if(booking?.item?.config?.isInternational){
			return booking.item.config.isInternational;
		}
		const service = booking?.service?.toUpperCase();
		if(!service){
			return false;
		}
		if(service.includes("FLIGHT")){
			return isInternationalFlight(booking);
		}else if(service.includes("HOTEL")){
			return isInternationalHotel(booking);
		}else{
			return false;
		}
	}catch (e){
		return false;
	}
}

// Checks whether the booking is International based on the segments
export const isInternationalFlight = (booking) =>{
	// gets the segments array from the ticket
	const segments = booking?.ticket?.segments;
	// if no segments retrun false
	if(!segments || segments.length === 0){
		return false;
	}
	let international = false;
	// iterate over flight segments and check if country is not India for arrival or departure
	segments.forEach((segment)=>{
		let arrivalCountry = segment.arrival?.country?.toUpperCase();
		let departureCountry = segment.departure?.country?.toUpperCase();
		if(arrivalCountry!=="INDIA" || departureCountry!=="INDIA"){
			international=true;
			return;
		}
	})
	return international;
}

// Checks whether the booking is International based on the segments
export const isInternationalHotel = (booking) =>{
	// gets the criteria array from the ticket
	const criteria = booking?.ticket?.criteria;
	// if no criteria object retrun false
	if(!criteria){
		return false;
	}
	const country = criteria.country.toUpperCase();
	return country !== "INDIA";
}



// Define a mapping of state codes to state names.
export const stateCodeToName = {
	'01': 'Jammu and Kashmir',
	'02': 'Himachal Pradesh',
	'03': 'Punjab',
	'04': 'Chandigarh',
	'05': 'Uttarakhand',
	'06': 'Haryana',
	'07': 'Delhi',
	'08': 'Rajasthan',
	'09': 'Uttar Pradesh',
	'10': 'Bihar',
	'11': 'Sikkim',
	'12': 'Arunachal Pradesh',
	'13': 'Nagaland',
	'14': 'Manipur',
	'15': 'Mizoram',
	'16': 'Tripura',
	'17': 'Meghalaya',
	'18': 'Assam',
	'19': 'West Bengal',
	'20': 'Jharkhand',
	'21': 'Orissa',
	'22': 'Chhattisgarh',
	'23': 'Madhya Pradesh',
	'24': 'Gujarat',
	'25': 'Daman and Diu',
	'26': 'Dadra and Nagar Haveli',
	'27': 'Maharashtra',
	'28': 'Andhra Pradesh',
	'29': 'Karnataka',
	'30': 'Goa',
	'31': 'Lakshadweep',
	'32': 'Kerala',
	'33': 'Tamil Nadu',
	'34': 'Puducherry',
	'35': 'Andaman and Nicobar Islands',
	'36': 'Telangana',
	'37': 'Ladakh',
	'38': 'Lakshadweep',
	'39': 'Chandigarh',
	'40': 'Delhi',
	'41': 'Lakshadweep',
};


const sellerAddress = (gstin) => {
	if(gstin && gstin.gstin==="27AAHCC1775A1ZX"){
		return CL_GSTS[gstin.gstin]
	}
	return gstin?.address || "-"
}

function placeOfSupply(gstNum) {
	// Check if GSTIN is valid and has at least 2 characters.
	if (typeof gstNum !== 'string' || gstNum.length < 2) {
		return 'Maharashtra';
	}

	// Extract the first two characters (state code) from the GSTIN.
	const stateCode = gstNum.slice(0, 2);

	// Check if the state code exists in the mapping.
	if (stateCode in stateCodeToName) {
		return stateCodeToName[stateCode];
	} else {
		return 'Maharashtra';
	}
}

export {
	placeOfSupply,sellerAddress
}
