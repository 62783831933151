/**
 * Author[Lakshay Jain]
 * Version 1.0.2
 * 7th December | 1.0.1 | Shubham Rawat | Hid the Approvals option from nav
 * 20th December | 1.0.2 | Shubham Rawat | Hid the Reports option from nav
*/

import React, { useEffect, useState } from 'react';
import { NotFound, Sidebar, Wrapper } from '../../../retro';
import { Route, Switch, useHistory } from 'react-router-dom';
import useConfigHook from 'App/Hooks/useConfigHook';
import useSidebarConfig from 'App/Hooks/Sidebar/useSidebarConfig';
import Logout from './assets/logout.svg';
import Settings from './assets/settings.svg';
import './assets/index.css';
import Clients from './Clients';
import Customize from './Customize';
import MarketPlace from "App/Pages/App/MarketPlace";
import Approvals from './Approvals';
import Accounts from './AccountDetails';
import './override.css';
import Moment from 'moment';
import { get, set } from 'App/Utils/Crypto';
import Apps from './Apps';
import Admin from './Admin';
import MetaApis from './MetaApis';
import ClientDetails from './ClientDetails';
import { useThemeHook } from 'App/Hooks/useThemeHook';
import useLogoHooks from 'App/Hooks/useLogoHooks';
import Dashboard from './Dashboard';
import SettingsComponent from './Settings';
import { useIdleTimer } from 'react-idle-timer';
import _ from 'lodash';

import Client from './assets/organisation.png';
import Api from './assets/api.png';
import UserIcon from './assets/user.png';
import Perks from './assets/trip.png';
import Hotel from './assets/hotel.png';
import { Alert } from 'retro/Alert/Alert';
import useFunctionallityHook from 'App/Hooks/useFunctionallityHook';
import useRoleHook from 'App/Hooks/useRoleHook';
import ROLES from 'App/Constants/Roles';
import Logs from './Logs';

const APPS_MANAGER = {
  "lakshay.jain@antrepriz.com" : true
}

const TOPITEMS = () => {
  const ITEMS = [];
  const CONFIG = useConfigHook();
  const {reportTabName, approvalsRequired, showReports} = useFunctionallityHook();
  const isAdmin = useRoleHook(ROLES.SUPER);
  const isClient = useRoleHook(ROLES.CLIENTS);
  const isClientWrite = useRoleHook(ROLES.CLIENTS_WRITE);
  const isApproval = useRoleHook(ROLES.APPROVALS);
  const isApprovalWrite = useRoleHook(ROLES.APPROVALS_WRITE);
  const isSuper  = useRoleHook(ROLES.SUPER);
  const PERSONS = {
    "aavishkar@antrepriz.com":true,
    "aditya.vyawhare@antrepriz.com":true,
    "madhu.sj@antrepriz.com":true,
    "garla.manasa@cleartrip.com": true,
    "mohamed.mansoor@cleartrip.com":true,
    "tasneem.ranijiwala@cleartrip.com":true,
    "vismay.buch@cleartrip.com":true
  }

  if (!CONFIG.loading) {
    if (isAdmin) {
      ITEMS.push({
        title: 'Admin',
        items: [
          {
            name: 'Admins',
            path: '/app/admins',
            type: 'includes',
            icon: <img className='topbar-icon' alt='icon' src={UserIcon} />,
          },
        ],
      });
    }

    if (isClient || isClientWrite) {
      ITEMS.push({
        title: 'Clients',
        items: [
          {
            name: 'Clients',
            path: '/app/clients',
            type: 'includes',
            icon: <img className='topbar-icon' alt='icon' src={Client} />,
            items: [
              {
                name: 'All Clients',
                path: '/app/clients',
                type: 'equals',
              },
            ],
          },
        ],
      });
    }
    const META = {
      title: 'Meta Apis',
      items: [],
    };
    const APPS = {
      title: 'Apps',
      items: [],
    };
    const INVENTORY = {
      title: 'Contracted Hotels',
      items: [],
    };
    META.items.push({
      name: reportTabName,
      path: '/app/apps/meta',
      type: 'includes',
      icon: <img className='topbar-icon' alt='icon' src={Api} />,
    });
    if (_.includes(CONFIG.apps, 'PERKS')) {
      APPS.items.push({
        name: 'Perks',
        path: '/app/apps/perks',
        type: 'includes',
        icon: <img className='topbar-icon' alt='icon' src={Perks} />,
      });
      INVENTORY.items.push({
        name: 'Contracted Hotels',
        path: '/app/apps/contracted',
        type: 'includes',
        icon: <img className='topbar-icon' alt='icon' src={Hotel} />,
      });
    }
    if(showReports){
      ITEMS.push(META);
    }
    if (APPS.items.length > 0) {
      ITEMS.push(APPS);
      if (isSuper){
      ITEMS.push(INVENTORY); }
    }
    const APPROVALS = {
      title: 'Approvals',
      items: [],
    };
    APPROVALS.items.push({
      name: 'Approvals',
      path: '/app/approvals',
      type: 'includes',
      icon: <img className='topbar-icon' alt='icon' src={Hotel} />,
    });
    // only if the approvals are required
    if(approvalsRequired){
      if (isApproval || isApprovalWrite) {
        ITEMS.push(APPROVALS);
      }
    }
    if (window.location.origin.includes( 'localhost')) {
      ITEMS.push({
        title: 'Customization',
        items: [
          {
            name: 'Customization',
            path: '/app/customize',
            type: 'includes',
            icon: <img className='topbar-icon' alt='icon' src={Client} />,
            items: [
              {
                name: 'Customization',
                path: '/app/customize',
                type: 'equals',
              },
            ],
          },
        ],
      });
    }
    if(APPS_MANAGER[CONFIG.email]){
      ITEMS.push({
        title: 'Apps Manager',
        items: [
          {
            name: 'Apps Manager',
            path: '/app/market',
            type: 'includes',
            icon: <img className='topbar-icon' alt='icon' src={Client} />,
            items: [
              {
                name: 'Apps Manage',
                path: '/app/market',
                type: 'equals',
              },
            ],
          },
        ],
      });
      
    }
    if(isSuper && PERSONS[CONFIG.email]){
      if (!(window.origin.includes("yesleap") || window.origin.includes("yespayleaf"))) {
      ITEMS.push({
        title: 'Logs',
        items:[
          {
            name: 'Logs',
            path: '/app/logs',
            type: 'includes',
            icon: <img className='topbar-icon' alt='icon' src={UserIcon} />,
          },
        ]
      })
    }
    }

  }
  
  return ITEMS;
};

function SidebarWrapper() {
  const AppIcon = useLogoHooks();
  const CONFIG = useConfigHook();
  const ITEMS = useSidebarConfig();
  const HISTORY = useHistory();
  const dropDown = [
    {
      icon: (
        <svg width='1.4rem' height='1.4rem' className='mr1'>
          <image xlinkHref={Settings} width='1.4rem' height='1.4rem' />
        </svg>
      ),
      label: 'Account Settings',
      onClick: () => HISTORY.push(`/app/settings`),
    },
    {
      icon: (
        <svg width='1.4rem' height='1.4rem' className='mr1'>
          <image xlinkHref={Logout} width='1.4rem' height='1.4rem' />
        </svg>
      ),
      label: 'Log Out',
      onClick: () => {
        localStorage.clear();
        location.reload();
      },
    },
  ];
  return (
    <>
      <Sidebar
        dropDown={dropDown}
        topItems={TOPITEMS()}
        flexible={true}
        search={false}
        data={{
          name: `${CONFIG.name} - ${CONFIG.origin.code}`,
          email: CONFIG.email,
          children: (
            <div id='sidebar-top' className='flex align-center mt2'>
              <div
                onClick={() => {
                  HISTORY.push('/app/settings');
                }}
                className='icon mr1'
              >
                <svg width='1.4rem' height='1.4rem'>
                  <image xlinkHref={Settings} width='1.4rem' height='1.4rem' />
                </svg>
              </div>
              <div
                onClick={() => {
                  localStorage.clear();
                  window.location.reload();
                }}
                className='icon'
              >
                <svg width='1.4rem' height='1.4rem'>
                  <image xlinkHref={Logout} width='1.4rem' height='1.4rem' />
                </svg>
              </div>
            </div>
          ),
        }}
        items={ITEMS}
        logo={AppIcon}
      ></Sidebar>
    </>
  );
}

export default function App() {
  const [state, setState] = useState('Active');
  let sessionTimeout = 90;
  if (window.location.href.includes('superadmin.yesleap.in') || window.location.href.includes('admin.yespayleaf.in')) {
    sessionTimeout = 10;
  }
  let sessionTimer = sessionTimeout * 60 * 1000;
  const onIdle = () => {
    setState('Idle');
  };

  useEffect(() => {
    let time = get('time.active');
    if (time) {
      time = parseInt(time, 10);
      if (time < Moment().valueOf() - sessionTimer) {
        setState('Idle');
      }
    }
  }, []);

  useIdleTimer({
    onIdle,
    onAction: () => {
      if (state === 'Active') {
        set('time.active', Moment().valueOf());
      }
    },
    timeout: sessionTimer,
    throttle: 500,
  });
  useEffect(() => {
    useThemeHook();
  }, []);

  if (state === 'Idle') {
    return (
      <div className='bg-white flex h-100 w-100 vertically center'>
        <h3 className='fw-bold mt4'>Inactive Session</h3>
        <p>Your account is inactive for more then {sessionTimeout} minutes</p>
        <p
          onClick={() => {
            localStorage.clear();
            location.reload();
          }}
          className='btn btn-black mt4 btn-sm'
        >
          Log Out
        </p>
      </div>
    );
  }
  return (
    <>
      <Alert.Container />
      <SidebarWrapper />
      <Switch>
        <Route path='/app/admins'>
          <Admin />
        </Route>
        <Route path='/app/client/:id'>
          <ClientDetails />
        </Route>
        <Route path='/app/clients'>
          <Clients />
        </Route>
        <Route path='/app/accounts/:clientId/:id'>
          <Wrapper>
            <Accounts />
          </Wrapper>
        </Route>
        <Route path='/app/apps/meta'>
          <MetaApis />
        </Route>
        <Route path='/app/apps'>
          <Apps />
        </Route>
        <Route path='/app/market'>
          <MarketPlace />
        </Route>
        <Route path='/app/customize'>
          <Customize />
        </Route>
        <Route path='/app/settings'>
          <SettingsComponent />
        </Route>
        <Route path='/app/approvals'>
          <Approvals />
        </Route>
        {!(window.origin.includes("yesleap") || window.origin.includes("yespayleaf")) && (
        <Route path='/app/logs'>
          <Logs />
        </Route>
        )}
        <Route path='/'>
          <Wrapper>
            <Dashboard />
          </Wrapper>
        </Route>
        <NotFound />
      </Switch>
    </>
  );
}
