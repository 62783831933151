import React, {useRef, useState} from 'react'
import { useHistory } from 'react-router-dom';
import {Button, CheckBox, Form, Input, NavDivider, Navigation, TOAST} from "../../../../../../retro";
import {post} from "App/Network/Axios";
import useConfigHook from "../../../../../Hooks/useConfigHook";
import SearchCities from 'App/Components/Inputs/HotelSearchInput/HotelSearchInput';

export default function CreateClient() {
    const HISTORY = useHistory()
    const NAME = useRef();
    const REGISTERED = useRef();
    const REFERENCE = useRef();
    const EMAIL = useRef();
    const CODE = useRef();
    const GSTIN = useRef();
    const TAN = useRef();
    const PAN = useRef();
    const ADDRESS = useRef()
    const MOBILE = useRef()
    const [checked,setChecked] = useState(true)
    const [loading,setLoading] = useState(false);
    const CONFIG = useConfigHook();
    const [city, setCity] = useState({})

    return (
        <>
            <Navigation backUrl={''} title="Create Client" chips={['All Clients', "Create Client"]} description="Enter details to add a new client."/>
            <NavDivider/>
            <Form focus={true} className="mt6" containerStyle={{width:'100%',maxWidth:600}}>
                <Input ref={NAME} type="text" label="Client Name" placeholder="enter client name" morph={true}/>
                <Input ref={REGISTERED} type="text" label="Registered Name" placeholder="enter registered name" morph={true}/>
                <Input ref={CODE} type="text" label="Client Code" placeholder="enter client code" morph={true}/>
                <Input ref={EMAIL} type="text" label="Email Address" placeholder="enter email address" morph={true}/>
                <Input ref={REFERENCE} type="text" label="Reference/Onboarding Id" placeholder="enter onboarding id" morph={true}/>

                <Input ref={GSTIN} type="text" label="Client Gstin(Optional)" placeholder="enter client gstin" morph={true}/>
                <Input ref={PAN} type="text" label="Client Pan(Optional)" placeholder="enter client pan" morph={true}/>
                <Input ref={TAN} type="text" label="Client Tan(Optional)" placeholder="enter client tan" morph={true}/>

                
                {
                    CONFIG.origin.code === 'CLRTRP' || 
                    CONFIG.origin.code === 'ADANI' ||
                    CONFIG.origin.code === 'CLTP-STG' ? (
                    <>
                    <Input ref={ADDRESS} type="text" label="Client address" placeholder="enter client address" morph={true}/>
                    {/* search city */}
                    <label style={{marginTop: '16px'}}>Select City</label>
                    <SearchCities
                        recentCities={true}
                    hide={true}
                    className="mt1"
                    api="/meta/cities/search"
                    onSelect={(city) => {
                    setCity(city)
                    }}
                    selected={city}
                    placeholder={"Search City"}
                    renderResult={(item) => (
                    <div>
                        <p>
                        {item?.name}
                        {item.country === "India" ? `, ${item?.state}` : ""},{" "}
                        {item?.country}
                        </p>
                    </div>
                    )}
                    />
                    {/* updates state after selecting city */}
                    <Input
                        label="Select State"
                        className="mt2"
                        defaultValue={city.state}
                        disabled={true}
                        placeholder="Select State"
                    />
                    <Input ref={MOBILE} type="text" label="Client mobile" placeholder="enter client mobile number" morph={true}/>
                    </>
                    ): null
                }
                
                {
                    CONFIG.origin.origin==="CLRTRP"&&	<div className="mt4 mb2" >
                        <CheckBox checked={checked} onChange={()=>{
                            setChecked(!checked)
                        }} label="Do you want to create ooo for this client."/>
                    </div>
                }
                <Button onClick={()=>{
                    let name = NAME.current.value;
                    let registered = REGISTERED.current.value;
                    let email = EMAIL.current.value;
                    let reference = REFERENCE.current.value;
                    let code = CODE.current.value;
                    let pan = PAN.current.value;
                    let tan = TAN.current.value;
                    let gstin = GSTIN.current.value;
                    let address = "";
                    let mobile = "";
                    let state = "";
                    let cityName = "";

                    if (
                        CONFIG.origin.code === 'CLRTRP' || 
                        CONFIG.origin.code === 'ADANI' ||
                        CONFIG.origin.code === 'CLTP-STG'
                    ) {
                        address = ADDRESS.current.value;
                        mobile = MOBILE.current.value; 
                        state = city.state;
                        cityName = city.name;
                    
                    if(!address){
                        TOAST.error("Enter a valid address")
                        return
                    }
                    if(!city.name){
                        TOAST.error("Enter a valid city")
                        return
                    }
                    if(!city.state){
                        TOAST.error("Enter a valid state")
                        return
                    }
                    if(!mobile){
                        TOAST.error("Enter a valid mobile number")
                        return
                    }
                }

                    if(!name){
                        TOAST.error("Enter a valid name")
                        return
                    }
                    if(!registered){
                        TOAST.error("Enter a valid registered name")
                        return
                    }
                    if(!email){
                        TOAST.error("Enter a valid email")
                        return
                    }
                    if(!code){
                        TOAST.error("Enter a valid code")
                        return
                    }
                    let data = {
                        name,email,reference,registered,code,pan,tan,gstin, city:city.name,
                        ...(
                            CONFIG.origin.code === 'CLRTRP' || 
                            CONFIG.origin.code === 'ADANI' ||
                            CONFIG.origin.code === 'CLTP-STG'
                        ? { address, state, mobile, city: cityName }
                        : {}),
                        ooo:checked?'yes':"no"
                    }
                    setLoading(true);
                    post('/clients',data,(e,r)=>{
                        if(r){
                            REGISTERED.current.value = ""
                            NAME.current.value = "";
                            REFERENCE.current.value = "";
                            EMAIL.current.value = "";
                            CODE.current.value = "";
                            if (
                                CONFIG.origin.code === 'CLRTRP' || 
                                CONFIG.origin.code === 'ADANI' ||
                                CONFIG.origin.code === 'CLTP-STG'
                            ) {
                                if (ADDRESS?.current) {
                                    ADDRESS.current.value = "";
                                }
                                if (MOBILE?.current) {
                                    MOBILE.current.value = "";
                                }
                                setCity({});
                                }
                            TOAST.success("Client on-boarded successfully!!")
                            HISTORY.push('/app/clients');
                        }else{
                            TOAST.handleError(e)
                        }
                        setLoading(false);
                    })
                }} margin="mt3" loading={loading}>
                    Create Client
                </Button>
            </Form>
        </>
    )
}
