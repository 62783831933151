import { axios } from "./Axios";
import qs from "qs";
import { parseJson } from "../Utils/Utils";
import * as Crypto from "../Utils/Crypto";
import Moment from "moment";

class Wrapper {
  url;

  Cancellable = (promise, mCancel = false) => {
    let hasCompleted = false;
    const wrappedPromise = new Promise((resolve, reject) => {
      promise.then(
        (val) => {
          if (hasCompleted) {
            reject({ isCanceled: true });
          } else {
            hasCompleted = true;
            resolve(val);
          }
        },
        (error) => {
          if (hasCompleted) {
            reject({ isCanceled: true });
          } else {
            reject(error);
          }
        },
      );
    });
    return {
      promise: wrappedPromise,
      cancel() {
        hasCompleted = true;
        if (mCancel) {
          mCancel();
        }
      },
      isCancellable() {
        return !hasCompleted;
      },
    };
  };

  constructor(url) {
    this.url = url;
  }

  pushRequest = (url, data, method, baseUrl) => {
    let self = this;
    if (!baseUrl) {
      baseUrl = self.url;
    }
    if (!data) {
      data = {};
    }

    if (url.startsWith("/")) {
      url = url.substr(1, url.length);
    }

    const token = Crypto.get("token");
    let session = "Not Defined";
    if (token) {
      session = token.substr(40, 30);
    }
    let request = {
      sessionId: session,
      data: {
        ...data,
        apiUrl: baseUrl + url,
      },
      platform: "dashboard",
      method,
      type: "api",
    };
  };

  get = (url, cb, data = {}) => {
    if (url.startsWith("/")) {
      url = url.substr(1, url.length);
    }
    let self = this;
    let cancel = false;
    let cancelToken = new axios.CancelToken(function executor(c) {
      cancel = c;
    });
    let cancellable = self.Cancellable(
      axios.get(`${this.url}${url}`, {
        params: data,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
        cancelToken,
      }),
      cancel,
    );
    let currentTs = Moment().valueOf();
    cancellable.promise
      .then((res) => {
        if (url.includes(".csv")) {
          cb(null, parseJson(res.data));
        } else {
          cb(null, res.data);
        }
        {
          let responseTs = Moment().valueOf();
          let time = responseTs - currentTs;
          data.code = 200;
          data.time = time;
          self.pushRequest(url, data, "get");
        }
      })
      .catch((err) => {
        if (err && !err.isCanceled) {
          cb(err);
          {
            let responseTs = Moment().valueOf();
            let time = responseTs - currentTs;
            data.time = time;
            data.error = err.data;
            data.code = err.code;
            self.pushRequest(url, data, "get");
          }
        }
      });
    return cancellable;
  };

  delete = (url, cb, data = {}) => {
    if (url.startsWith("/")) {
      url = url.substr(1, url.length);
    }
    let self = this;
    self.pushRequest(url, {}, "delete");
    let cancellable = self.Cancellable(
      axios.delete(`${self.url}${url}`, {
        data,
      }),
    );
    cancellable.promise
      .then((res) => {
        cb(null, res.data);
      })
      .catch((err) => {
        if (err && !err.isCanceled) {
          cb(err);
        }
      });
    return cancellable;
  };

  post = (url, data, cb) => {
    if (url.startsWith("/")) {
      url = url.substr(1, url.length);
    }
    let self = this;
    let currentTs = Moment().valueOf();

    let cancellable = null;
    if (data.baseUrl) {
      let baseUrl = data.baseUrl;
      delete data.baseUrl;
      cancellable = self.Cancellable(
        axios.post(`${baseUrl}admin/${url}`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        }),
      );
    } else {
      cancellable = self.Cancellable(
        axios.post(`${self.url}${url}`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        }),
      );
    }
    cancellable.promise
      .then((res) => {
        cb(null, res.data);
        {
          let responseTs = Moment().valueOf();
          let time = responseTs - currentTs;
          data.code = 200;
          data.time = time;
          self.pushRequest(url, data, "post");
        }
      })
      .catch((err) => {
        if (err && !err.isCanceled) {
          cb(err);
          {
            let responseTs = Moment().valueOf();
            let time = responseTs - currentTs;
            data.time = time;
            data.error = err.data;
            data.code = err.code;
            self.pushRequest(url, data, "get");
          }
        }
      });
    return cancellable;
  };

  patch = (url, data, cb) => {
    if (url.startsWith("/")) {
      url = url.substr(1, url.length);
    }
    let self = this;
    self.pushRequest(url, data, "patch");
    let cancellable = self.Cancellable(axios.patch(`${this.url}${url}`, data));
    cancellable.promise
      .then((res) => {
        cb(null, res.data);
      })
      .catch((err) => {
        if (err && !err.isCanceled) {
          cb(err);
        }
      });
    return cancellable;
  };
}

export { Wrapper };
